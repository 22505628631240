import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingView } from '../../common';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import './report.scss';
import { Flowchart } from './Flowchart';
import { get, post } from '../redux/queryCRAReportDetails';

export const WorkFlowStatus = props => {
  const dispatch = useDispatch();

  const [reportStatus, setReportStatus] = useState(false);
  const [fleetApiStatus, setFleetApiStatus] = useState(false);
  const [fleetSFTPStatus, setFleetSFTPStatus] = useState(false);
  const [fleetEmailStatus, setFleetEmailStatus] = useState(false);
  const [fleetApiList, setFleetApiList] = useState([]);
  const [fleetSftpList, setFleetSftpList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [modal, setModal] = useState(false);
  const [tableType, setTableType] = useState(1);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [emailField, setEmailField] = useState('');
  const [emailFieldList, setEmailFieldList] = useState([]);
  const [emailFieldError, setEmailFieldError] = useState(false);
  const [emailStatusText, setEmailStatusText] = useState('-');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isApiBtnTapped, setIsApiBtnTapped] = useState(false);
  const [isSFTPBtnTapped, setIsSFTPBtnTapped] = useState(false);
  const [isEmailBtnTapped, setIsEmailBtnTapped] = useState(false);
  const [isApi, setIsApi] = useState(true);

  useEffect(() => {
    fetchWFStatus();
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
  }, []);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const handleStatus = async (isApi = true) => {
    if (isApi) {
      setIsApiBtnTapped(true);
    } else {
      setIsSFTPBtnTapped(true);
    }
    await fetchWFQueueStatus(isApi ? 'POST_JSON' : 'POST_FILE');
  };

  const fetchWFQueueStatus = async type => {
    const { completeJson, ziplocation } = props;
    toggleProgress(true);
    try {
      const response = await dispatch(
        post({
          path: '/cra/fleetresponse/queue',
          body: { reportID: completeJson.reportID, TYPE: type },
        }),
      );
      showModal(5);
    } catch (error) {
      console.log('error' + error);
    } finally {
      toggleProgress(false);
    }
  };

  const fetchWFStatus = async () => {
    const { completeJson } = props;
    setReportStatus(completeJson.isReportComplete);
    toggleProgress(true);
    try {
      const response = await dispatch(
        get({
          path: '/cra/wfstatus',
          queryStringParameters: {
            reportID: completeJson.reportID,
          },
        }),
      );
      toggleProgress(false);
      setIsApiBtnTapped(false);
      setIsSFTPBtnTapped(false);
      setIsEmailBtnTapped(false);
      let sftpArray = [];
      let apiJsonArray = [];
      let mailList = [];
      if (response.fleetresponseJSONactivitylog != null) {
        apiJsonArray = response.fleetresponseJSONactivitylog;
        setFleetApiList([...apiJsonArray]);
        if (apiJsonArray != null && apiJsonArray.length > 0) {
          let itemStatus = apiJsonArray[0];
          if (itemStatus.status === 'Failed') {
            setFleetApiStatus(true);
          } else {
            setFleetApiStatus(false);
          }
        }
      }
      if (response.fleetresponseSFTPactivitylog != null) {
        sftpArray = response.fleetresponseSFTPactivitylog;
        setFleetSftpList([...sftpArray]);
        if (sftpArray != null && sftpArray.length > 0) {
          let itemStatus = sftpArray[0];
          if (itemStatus.status === 'Failed') {
            setFleetSFTPStatus(true);
          } else {
            setFleetSFTPStatus(false);
          }
        }
      }
      if (response.emailactivitylog != null) {
        mailList = response.emailactivitylog;
        setEmailList([...mailList]);
        if (mailList != null && mailList.length > 0) {
          let itemStatus = mailList[0];
          if (itemStatus.emailSendStatus === 'Failed') {
            setFleetEmailStatus(true);
          } else {
            setFleetEmailStatus(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
      toggleProgress(false);
      setIsApiBtnTapped(false);
      setIsSFTPBtnTapped(false);
      setIsEmailBtnTapped(false);
    }
  };

  const postEmailData = () => {
    toggle();
    if (emailField != '' && !emailFieldError) {
      var mailListData = emailField.split(',');
      setEmailFieldList([...mailListData]);
      callEmailApi(mailListData);
    } else {
      callEmailApi([]);
    }
  };

  const callEmailApi = async mailListData => {
    const { completeJson } = props;
    toggleProgress(true);
    setIsEmailBtnTapped(true);
    setIsEmailLoading(true);
    try {
      const response = await dispatch(
        post({
          path: '/cra/notify/resendemail',
          queryStringParameters: {
            reportID: completeJson.reportID,
          },
          body: { extraEmails: mailListData },
        }),
      );
      setEmailField('');
      toggleProgress(false);
      showModal(5);
      if (response != null && response.status === 'Success') {
        setEmailStatusText('Successfully sent');
      } else {
        setEmailStatusText('Failed to send, try again.');
      }
    } catch (error) {
      console.log('error' + error);
    } finally {
      setIsEmailLoading(false);
    }
  };

  const toggleProgress = stat => {
    setIsLoading(stat);
  };

  const toggle = () => {
    setEmailFieldList([]);
    setEmailField('');
    setEmailStatusText('-');
    setIsEmailSend(false);
    setModal(!modal);
  };

  const showModal = stat => {
    setTableType(stat);
    setModal(true);
  };

  const handleEmail = stat => {
    setIsEmailSend(stat);
    showModal(3);
  };

  const handleEmailField = text => {
    setEmailField(text);
  };

  const handlePartialAlert = status => {
    setIsApi(status); /**To check if it is API or SFTP */
    console.log('isAPI', isApi);
    showModal(4);
  };

  const handlePartialSubmit = () => {
    toggle();
    handleStatus(isApi);
  };

  useEffect(() => {
    if (emailField != '') {
      if (validateEmailList(emailField)) {
        setEmailFieldError(false);
      } else {
        setEmailFieldError(true);
      }
    } else {
      setEmailFieldError(false);
    }
  }, [emailField]);

  const validateEmailList = raw => {
    var emails = raw.split(',');
    var valid = true;
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      if (emails[i] === '' || !regex.test(emails[i].replace(/\s/g, ''))) {
        valid = false;
      }
    }
    return valid;
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingView backdrop={true} />}
      <span className="isMobView">
        <p style={{ margin: '90px auto', textAlign: 'center' }} className="p4-black">
          As of now, this page is displayed only on larger screen
        </p>
      </span>
      <span className="isWebView">
        <div className="main-heading">
          <h3 className="p4-black" style={{ display: 'inline' }}>
            Work Flow Status
          </h3>
          <button
            style={{ marginLeft: '20px', marginTop: '19px' }}
            className="btn btn-danger btn btn-secondary"
            onClick={fetchWFStatus}>
            {'Refresh'}
          </button>
        </div>
        <Flowchart
          {...props}
          fleetApiStatus={fleetApiStatus}
          fleetSFTPStatus={fleetSFTPStatus}
          fleetEmailStatus={fleetEmailStatus}
          fleetApiList={fleetApiList}
          fleetSftpList={fleetSftpList}
          emailList={emailList}
          showModal={index => showModal(index)}
          handleStatus={stat => handleStatus(stat)}
          handleEmail={stat => handleEmail(stat)}
          handlePartialAlert={stat => handlePartialAlert(stat)}
          isApiBtnTapped={isApiBtnTapped}
          isSFTPBtnTapped={isSFTPBtnTapped}
          isEmailBtnTapped={isEmailBtnTapped}
        />
      </span>
      <Modal
        isOpen={modal}
        toggle={toggle}
        fade={false}
        backdrop={false}
        style={{
          // maxWidth: `${width > 400 ? width - 50 : width + 'px'}`, //'1600px',
          // width: `${width > 400 ? width - 50 : width + 'px'}`, // '80%',
          width: '100%',
        }}
        className="modalsTable"
        size={`${tableType === 2 ? 'xl' : 'lg'}`}>
        <ModalHeader toggle={toggle}>
          {tableType === 1
            ? 'API'
            : tableType === 2
            ? 'SFTP'
            : tableType === 3
            ? 'EMAIL'
            : tableType === 4
            ? 'Confirm Submission'
            : tableType === 5
            ? 'Request Registered'
            : 'NOTIFY'}
        </ModalHeader>
        <ModalBody>
          <div>
            {isEmailSend ? (
              <div>
                <div style={{ fontWeight: 'bold' }}>Report ID</div>
                {props.completeJson.reportID}
                <div style={{ fontWeight: 'bold', marginTop: 20 }}>Technician Email ID</div>
                <span>
                  {props?.completeJson?.SubmittedTechId.toLocaleLowerCase().replace(
                    'technicians_',
                    '',
                  )}
                </span>
                <div style={{ fontWeight: 'bold', marginTop: 20 }}>
                  Add recipients (separate by comma ,) - Optional
                </div>
                <div className="emailContent">
                  <div className="emailErrorContent" style={{ width: '70%' }}>
                    <input
                      type="text"
                      style={{ width: '100%', height: '30px' }}
                      value={emailField}
                      onChange={text => handleEmailField(text.target.value)}
                    />
                    {emailFieldError && <small style={{ color: 'red' }}>Invalid email</small>}
                  </div>
                  <button
                    className={`emailSendButton send`}
                    style={{ backgroundColor: isEmailLoading ? '#9F9F9F' : 'rgb(58,123, 229)' }}
                    disabled={isEmailLoading}
                    onClick={() => postEmailData()}>
                    {'SEND'}
                  </button>
                  {/* {isEmailLoading && <Loader className='spinner' type="Circles" color="#fc0345" height={80} width={80} ></Loader>} */}
                </div>
              </div>
            ) : tableType === 4 ? (
              <div>
                <p>Do you want to submit the partial claim?</p>
                <p>
                  Once submitted, the claim can not be resubmitted. Rather notify the technician to
                  submit the claim fully
                </p>
                <button className="btn btn-danger btn btn-secondary" onClick={handlePartialSubmit}>
                  Yes, I understand and Submit
                </button>
                <button className="btn btn-danger btn btn-secondary" onClick={toggle}>
                  No, Do not submit
                </button>
              </div>
            ) : tableType === 5 ? (
              <div>
                <p>Posted the message to the queue.</p>
                <p>Please click on refresh button after 5 seconds to know the status</p>
              </div>
            ) : tableType === 6 ? (
              <div>
                <p>Work in progress.</p>
                <p>Push notification to notify the technician</p>
              </div>
            ) : (
              <div>
                <table id="default-view" className="condensed striped hover">
                  <tr className="activitylog-tr">
                    <td className="p4-black-light table-header">{'Date & Time'}</td>
                    <td className="p4-black-light table-header">{'Status'}</td>
                    {tableType === 1 && (
                      <td className="p4-black-light table-header">
                        {'Fleet Response Submission ID'}
                      </td>
                    )}
                    {tableType === 1 && (
                      <td className="p4-black-light table-header">{'Number of records added'}</td>
                    )}
                    <td className="p4-black-light table-header">
                      {tableType === 3 ? 'Email List' : 'Details'}
                    </td>
                  </tr>

                  {tableType === 1 ? (
                    fleetApiList.length > 0 ? (
                      fleetApiList.map((item, key) => (
                        <tr key={`fleetAPiList-0${key}`}>
                          <td className="p4-black-light">
                            {moment(item.interactiontime).format('MM/DD/YYYY hh:mm A Z')}
                          </td>
                          <td className="p4-black-light">{item.status}</td>
                          <td className="p4-black-light">
                            {item.status === 'Failed'
                              ? JSON.parse(item.errorDetails).ClaimSubmissionIdentifier
                              : JSON.parse(item.response).ClaimSubmissionIdentifier}
                          </td>
                          <td className="p4-black-light">
                            {item.status === 'Failed'
                              ? JSON.parse(item.errorDetails).RecordCount
                              : JSON.parse(item.response).RecordCount}
                          </td>
                          <td className="p4-black-light">
                            {item.status === 'Failed'
                              ? JSON.parse(item.errorDetails).Message
                              : JSON.parse(item.response).Message}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div style={{ marginLeft: 100, marginTop: 20 }}>No data found</div>
                    )
                  ) : tableType === 2 ? (
                    fleetSftpList.length > 0 ? (
                      fleetSftpList.map((item, key) => (
                        <tr key={`fleetSftpList-0${key}`}>
                          <td className="p4-black-light">
                            {moment(item.interactiontime).format('MM/DD/YYYY hh:mm A Z')}
                          </td>
                          <td className="p4-black-light">{item.status}</td>
                          <td className="p4-black-light">
                            {item.status === 'Failed' ? item.errorDetails : item.response}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div style={{ marginLeft: 100, marginTop: 20 }}>No data found</div>
                    )
                  ) : emailList.length > 0 ? (
                    emailList.map((item, key) => (
                      <tr key={`emailList-0${key}`}>
                        <td className="p4-black-light">
                          {moment(item.emailSendTime).format('MM/DD/YYYY hh:mm A Z')}
                        </td>
                        <td className="p4-black-light">{item.emailSendStatus}</td>
                        <td className="p4-black-light">
                          {item.emailSendStatus === 'Failed'
                            ? JSON.stringify(item.error.displayMessage)
                            : item.emailSendList &&
                              item.emailSendList.map(data => {
                                return <div>{data}</div>;
                              })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div style={{ marginLeft: 100, marginTop: 20 }}>No data found</div>
                  )}
                </table>
                <table id="responsive-view" className="condensed striped hover">
                  {tableType === 1 ? (
                    fleetApiList.length > 0 ? (
                      fleetApiList.map((item, key) => (
                        <tr key={`fleetAPiList-1${key}`}>
                          <td className="p4-black-light">
                            <tr>{moment(item.interactiontime).format('MM/DD/YYYY hh:mm A Z')}</tr>
                            <tr>{item.status}</tr>
                            <tr>{item.status === 'Failed' ? item.errorDetails : item.response}</tr>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div style={{ marginLeft: 100, marginTop: 20 }}>No data found</div>
                    )
                  ) : tableType === 2 ? (
                    fleetSftpList.length > 0 ? (
                      fleetSftpList.map((item, key) => (
                        <tr key={`fleetSftpList-0${key}`}>
                          <td className="p4-black-light">
                            <tr>{moment(item.interactiontime).format('MM/DD/YYYY hh:mm A Z')}</tr>
                            <tr>{item.status}</tr>
                            <tr>{item.status === 'Failed' ? item.errorDetails : item.response}</tr>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div style={{ marginLeft: 100, marginTop: 20 }}>No data found</div>
                    )
                  ) : emailList.length > 0 ? (
                    emailList.map((item, key) => (
                      <tr key={`emailList-1${key}`}>
                        <td className="p4-black-light">
                          <tr>{moment(item.emailSendTime).format('MM/DD/YYYY hh:mm A Z')}</tr>
                          <tr>{item.emailSendStatus}</tr>
                          <tr>
                            {item.emailSendStatus === 'Failed'
                              ? JSON.stringify(item.error)
                              : item.emailSendList &&
                                item.emailSendList.map(data => {
                                  return <div>{data}</div>;
                                })}
                          </tr>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div style={{ marginLeft: 100, marginTop: 20 }}>No data found</div>
                  )}
                </table>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {tableType === 2 && (
            <p>
              *For partial reports and/or reports submitted on others behalf there may not be images
              and the SFTP call will show status as failed. This can be ignored.
            </p>
          )}
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
