import React, { useEffect } from 'react';
import { withOAuth } from 'aws-amplify-react';
import { LoadingView } from '../common';

export const DefaultPage = withOAuth(props => {
  useEffect(() => {
    props.OAuthSignIn();
  }, []);
  return <LoadingView className="login-default-page" />;
});
