export const CRA_REPORTS_LIST_BEGIN = 'CRA_REPORTS_BEGIN';
export const CRA_REPORTS_LIST_SUCCESS = 'CRA_REPORTS_SUCCESS';
export const CRA_REPORTS_LIST_FAILURE = 'CRA_REPORTS_FAILURE';
export const CRA_REPORTS_LIST_DISMISS_ERROR = 'CRA_REPORTS_DISMISS_ERROR';

export const CRA_REPORTS_COMPLETE_LIST_BEGIN = 'CRA_REPORTS_COMPLETE_BEGIN';
export const CRA_REPORTS_COMPLETE_LIST_SUCCESS = 'CRA_REPORTS_COMPLETE_SUCCESS';
export const CRA_REPORTS_COMPLETE_LIST_FAILURE = 'CRA_REPORTS_COMPLETE_FAILURE';
export const CRA_REPORTS_COMPLETE_LIST_DISMISS_ERROR = 'CRA_REPORTS_COMPLETE_DISMISS_ERROR';

export const SIDE_MENU_TOGGLE = 'SIDE_MENU_TOGGLE';
