import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { store } from './common/store';
import { routeConfig } from './common/routeConfig';
import Root from './Root';
import registerServiceWorker from './registerServiceWorker';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import './styles/index.scss';

import { Amplify, API } from 'aws-amplify';

import awsmobile from './aws-exports';

/*
if(process.env.REACT_APP_STAGE === 'development'){
    const url = require('url');
    const craURL = url.parse(window.location.href);
    let redirectURL = craURL.protocol +"//"+craURL.hostname+"/"
    console.log(" redirectURL " + redirectURL);
    awsmobile.Auth.oauth.redirectSignIn = redirectURL
    awsmobile.Auth.oauth.redirectSignOut = redirectURL
    Amplify.configure(awsmobile);
}
*/
Amplify.configure(awsmobile);

API.configure(awsmobile.API);

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(<Root store={store} routeConfig={routeConfig} />);

// registerServiceWorker();
