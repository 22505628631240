import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import { ReportConstants } from './ReportConstants';
import { FilePicker } from '../../../common/FileUpload';

export const EmployeeInjuries = props => {
  const [data, setData] = useState(props.jsondata ?? {});
  const [isDisabled, setIsDisabled] = useState(false);
  let count = 0;

  useEffect(() => {
    setData(props.jsondata);
    if (props.jsondata) {
      if (props.jsondata.injuriesList !== null) {
        count = Object.keys(props.jsondata.injuriesList).length;
      }
    }
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisabled(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="report_main_section">
        <span className="p4-black">Employee Injuries ({count})</span>
        <div>
          {data ? (
            <div>
              <p className="p4-black-sub-header">Injured: {data.isInjured ? 'Yes' : 'No'}</p>
              {data && data.injuriesList ? (
                data.injuriesList.map((item, key) => (
                  <div>
                    <p className="p4-black-light">Injury Info - {key + 1}</p>
                    <table className="table-details">
                      <tr>
                        <td className="p4-black-light">Name</td>
                        <td className="p4-black-light">{item.name ?? '---'}</td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Employee ID</td>
                        <td className="p4-black-light">{item.employeeId ?? '---'}</td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Description</td>
                        <td className="p4-black-light">{item.description ?? '---'}</td>
                      </tr>
                    </table>
                  </div>
                ))
              ) : (
                <div>
                  <span className="h5-heading">Employee Injury details not found</span>
                </div>
              )}
            </div>
          ) : (
            <div>
              <span className="h5-heading">Employee injury details not found</span>
            </div>
          )}
        </div>

        {ReportConstants.displayInputJson && (
          <div>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </div>
      <Col className="mobileUpload" style={{ marginTop: '3%' }}>
        <FilePicker
          screenName={'employeeInjuries'}
          reportDetails={props.reportDetails}
          isLoading={stat => props.isLoading(stat)}
          updateReportDetails={props.updateReportDetails}
          isDisabled={
            props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisabled
          }
        />
      </Col>
    </React.Fragment>
  );
};
