import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './report.scss';
import { ReportConstants } from './ReportConstants';
import { Row, Col } from 'reactstrap';
import { PinEntryPopUp } from '../../common';
import { maskImage } from '../redux/queryCRAReportDetails';

export const TechnicianInfo = props => {
  const { completeJson = {}, isExport = () => {} } = props;
  const { personalInfo, isReportComplete, reportID } = completeJson;

  const dispatch = useDispatch();
  const renderedReportItems = useSelector(state => state?.reportitems?.renderedReportItems);

  const [showModal, setShowModal] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState('');
  const [incorrectPIN, setIncorrectPIN] = useState(false);
  const [downloadType, setDownloadType] = useState('');
  const [selectedImagePath, setSelectedImagePath] = useState('');
  const [hiddenImages, setHiddenImages] = useState([]);

  const onSubmitPIN = () => {
    let trimmed_path = getTrimmedImagePath(selectedImagePath);
    let images = hiddenImages;
    let isToHide = false;
    if (images.includes(trimmed_path)) {
      images.splice(images.indexOf(trimmed_path), 1);
    } else {
      images.push(trimmed_path);
      isToHide = true;
    }
    setHiddenImages(images);
    dispatch(
      maskImage({
        reportID: props.reportId,
        payload: {
          imageUrl: trimmed_path,
          mask: isToHide,
        },
      }),
    );
  };

  const downloadTextFile = () => {
    let text = completeJson;
    let name = text.reportID + '.json';
    text = JSON.stringify(text);
    const a = document.createElement('a');
    const type = name.split('.').pop();
    a.href = URL.createObjectURL(
      new Blob([text], { type: `text/${type === 'txt' ? 'plain' : type}` }),
    );
    a.download = name;
    a.click();
  };

  const downloadLocalFile = fileName => {
    const a = document.createElement('a');
    a.href = fileName;
    a.click();
  };

  const handleHtml2Canvas = index => {
    return new Promise((resolve, reject) => {
      html2canvas(document.querySelector('#item' + index), {
        scrollX: 0,
        scrollY: 0,
        logging: true,
        letterRendering: 1,
        useCORS: true,
        height: window.outerHeight + window.innerHeight,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/jpeg');
        resolve({ imgData: imgData });
      });
    });
  };

  const preventDefault = e => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };

  const downloadPdf = () => {
    const filename =
      reportID !== null && reportID !== undefined && reportID !== ''
        ? reportID.replaceAll('@', '_') + '.pdf'
        : 'download.pdf';
    isExport(true);
    document.addEventListener('wheel', preventDefault, {
      passive: false,
    });
    const pdf = new jsPDF('p', 'pt', 'b0', true);
    let promiseList = [];
    setTimeout(() => {
      promiseList = renderedReportItems.map((data, index) => handleHtml2Canvas(index));
      Promise.all(promiseList)
        .then(async data => {
          data.map((item, index) => {
            let width = pdf.internal.pageSize.getWidth();
            let height = pdf.internal.pageSize.getHeight();
            pdf.setPage(index + 1);
            pdf.addImage(item.imgData, 'JPEG', 0, 50, width, height);
            if (index === data.length - 1) {
              pdf.save(filename);
              isExport(false);
              document.removeEventListener('wheel', preventDefault, false);
            } else {
              pdf.addPage();
            }
          });
        })
        .catch(error => {
          isExport(false);
        });
    }, 1000);
  };

  const showPinPopUp = type => {
    setShowModal(true);
    setDownloadType(type);
  };

  const submitButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    switch (downloadType) {
      case 'json':
        downloadTextFile();
        break;
      case 'pdf':
        downloadPdf();
        break;
      case 'zip':
        downloadLocalFile(props.ziplocation);
        break;
      case 'externalFile':
        downloadLocalFile(props.extrafinal_destination);
        break;
    }
  };

  const cancelButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
  };

  return (
    <div className="report_main_section overflow">
      {/* <div className="defaultDownloadJsonbutton">
            <button className="btn btn-danger downloadJsonbutton" onClick={downloadTextFile}>Download JSON <i className="fa fa-download" aria-hidden="true"></i></button>
            {props.ziplocation && <a className="downloadZipbutton" href={props.ziplocation}>
               <button className="btn btn-danger downloadJsonbutton">Download Image Zip <i className="fa fa-download" aria-hidden="true"></i></button>
            </a>
            }
         </div> */}
      <Row xs="1" md="1" lg="4" style={{ marginTop: '20px' }}>
        <Col xs="10" md="12" lg="8" className="small-screen-margin">
          <h3 className="p4-black">General Info</h3>
          <span className="p4-black">Report Details</span>
          <br />
          <table className="table-details">
            <tbody>
              <tr>
                <td className="p4-black-light">Report ID</td>
                <td className="p4-black-light">{reportID}</td>
              </tr>
              <tr>
                <td className="p4-black-light" nowrap="nowrap">
                  Report Submitted?
                </td>
                <td className="p4-black-light">{isReportComplete ? 'Completed' : 'Partial'}</td>
              </tr>
              <tr>
                <td className="p4-black-light" nowrap="nowrap">
                  Report Status
                </td>
                <td className="p4-black-light">{completeJson.PayloadStatus}</td>
              </tr>
            </tbody>
          </table>

          <span className="p4-black">Submission Details</span>
          <table className="table-details">
            <tbody>
              <tr>
                <td className="p4-black-light" nowrap="nowrap">
                  On Behalf Of
                </td>
                <td className="p4-black-light">
                  {personalInfo.onBehalfOf ? personalInfo.onBehalfOf : '---'}
                </td>
              </tr>
              <tr>
                <td className="p4-black-light" nowrap="nowrap">
                  Submitted By
                </td>
                <td className="p4-black-light">
                  {completeJson.SubmittedTechId
                    ? completeJson.SubmittedTechId.replace('Technicians_', '')
                    : '---'}
                </td>
              </tr>
            </tbody>
          </table>

          <span className="p4-black">Technician Info</span>
          <div>
            {personalInfo ? (
              <div>
                <table className="table-details">
                  <tbody>
                    <tr>
                      <td className="p4-black-light">Name</td>
                      <td className="p4-black-light">
                        {personalInfo.techName ? personalInfo.techName : '---'}
                      </td>
                    </tr>
                    <tr>
                      <td className="p4-black-light">ID</td>
                      <td className="p4-black-light">
                        {personalInfo.techID ? personalInfo.techID : '---'}
                      </td>
                    </tr>
                    <tr>
                      <td className="p4-black-light" nowrap="nowrap">
                        Accident Date
                      </td>
                      <td className="p4-black-light">
                        {personalInfo.accidentDate ? personalInfo.accidentDate : '---'}
                      </td>
                    </tr>
                    <tr>
                      <td className="p4-black-light">UnitNumber</td>
                      <td className="p4-black-light">
                        {personalInfo.techUnitNumber ? personalInfo.techUnitNumber : '---'}
                      </td>
                    </tr>
                    {personalInfo.businessLine && (
                      <tr>
                        <td className="p4-black-light">Business Line</td>
                        <td className="p4-black-light">{personalInfo.businessLine}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="p4-black-light">RegionName</td>
                      <td className="p4-black-light">
                        {personalInfo.techRegionName ? personalInfo.techRegionName : '---'}
                      </td>
                    </tr>
                    <tr>
                      <td className="p4-black-light">OfficeName</td>
                      <td className="p4-black-light">
                        {personalInfo.techOfficeName ? personalInfo.techOfficeName : '---'}
                      </td>
                    </tr>

                    <tr>
                      <td className="p4-black-light" nowrap="nowrap">
                        Manager Name
                      </td>
                      <td className="p4-black-light">
                        {personalInfo.managerName ? personalInfo.managerName : '---'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <br />
                <span className="h5-heading">Technician info details not found</span>
              </div>
            )}

            <span className="p4-black">Device Details</span>
            <br />
            <table className="table-details">
              <tbody>
                <tr>
                  <td className="p4-black-light" nowrap="nowrap">
                    AppVersion
                  </td>
                  <td className="p4-black-light">
                    {completeJson.appVersion ? completeJson.appVersion : '---'}
                  </td>
                </tr>
                <tr>
                  <td className="p4-black-light" nowrap="nowrap">
                    OS
                  </td>
                  <td className="p4-black-light">
                    {completeJson.platform ? completeJson.platform : '---'}
                  </td>
                </tr>
                <tr>
                  <td className="p4-black-light" nowrap="nowrap">
                    OS Version
                  </td>
                  <td className="p4-black-light">
                    {completeJson.appOSVersion ? completeJson.appOSVersion : '---'}
                  </td>
                </tr>
                <tr>
                  <td className="p4-black-light" nowrap="nowrap">
                    Device Brand
                  </td>
                  <td className="p4-black-light">
                    {completeJson.deviceBrand ? completeJson.deviceBrand : '---'}
                  </td>
                </tr>

                <tr>
                  <td className="p4-black-light" nowrap="nowrap">
                    Device Name
                  </td>
                  <td className="p4-black-light">
                    {completeJson.deviceName ? completeJson.deviceName : '---'}
                  </td>
                </tr>

                <tr>
                  <td className="p4-black-light" nowrap="nowrap">
                    Device Model
                  </td>
                  <td className="p4-black-light">
                    {completeJson.deviceModel ? completeJson.deviceModel : '---'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs="10" md="12" lg="4" className="small-screen-margin">
          <div className="defaultDownloadJsonbutton">
            <button
              className="btn btn-danger downloadJsonbutton"
              onClick={() => showPinPopUp('json')}>
              Download JSON <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {props.ziplocation && (
              <div className="downloadZipbutton">
                <button
                  className="btn btn-danger downloadJsonbutton"
                  onClick={() => showPinPopUp('zip')}>
                  Download Image Zip <i className="fa fa-download" aria-hidden="true"></i>
                </button>
              </div>
            )}
            <button
              className="btn btn-danger downloadJsonbutton"
              onClick={() => showPinPopUp('pdf')}>
              Export as PDF
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {props.extrafinal_destination && (
              <button
                className="btn btn-danger downloadJsonbutton"
                onClick={() => showPinPopUp('externalFile')}>
                Download Extra Files <i className="fa fa-download" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </Col>
      </Row>
      <div className="flex-container">
        <div className="responsiveDownloadJsonbutton">
          <button
            className="btn btn-danger downloadJsonbutton"
            onClick={() => showPinPopUp('json')}>
            Download JSON <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          {props.ziplocation && (
            <button
              className="btn btn-danger downloadJsonbutton"
              onClick={() => showPinPopUp('zip')}>
              Download Image Zip <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          )}
          <button
            className="btn btn-danger downloadJsonbutton downloadPdf"
            onClick={() => showPinPopUp('pdf')}>
            Export as PDF <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          {props.extrafinal_destination && (
            <a className="downloadZipbutton" onClick={() => showPinPopUp('externalFile')}>
              <button className="btn btn-danger downloadJsonbutton">
                Download Extra Files <i className="fa fa-download" aria-hidden="true"></i>
              </button>
            </a>
          )}
        </div>
      </div>
      {ReportConstants.displayInputJson ? (
        <div>
          <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
        </div>
      ) : (
        ''
      )}
      <div>
        <PinEntryPopUp
          onHide={() => cancelButtonTapped()}
          show={showModal}
          setPIN={text => setEnteredPIN(text)}
          pinError={incorrectPIN}
          onSubmit={() => submitButtonTapped()}
        />
      </div>
    </div>
  );
};
