import {
  CRA_REPORTS_ACTIVITYLOG_BEGIN,
  CRA_REPORTS_ACTIVITYLOG_SUCCESS,
  CRA_REPORTS_ACTIVITYLOG_FAILURE,
  CRA_REPORTS_ACTIVITYLOG_DISMISS_ERROR,
} from './constants';
import { REACT_APP_API_KEY } from '../../../aws-exports';
import { API } from 'aws-amplify';
import { checkUser, updateAuthToken } from '../../home/redux/checkUser';

export const activityLogCRAPayload = ({ reportID, activity, comment }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      (async () => {
        await dispatch({
          type: CRA_REPORTS_ACTIVITYLOG_BEGIN,
        });
        const { jwt, email } = await getState().home;
        activity = {
          username: email,
          activitytime: new Date(),
          activity: activity,
        };
        if (comment) {
          activity.comment = comment;
        }

        try {
          if (!email) {
            await dispatch(checkUser());
          }
          const apiName = 'crareportapi';
          const path = '/cra/activitylog';
          const options = {
            // OPTIONAL
            headers: {
              Authorization: jwt,
              'x-api-key': REACT_APP_API_KEY,
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)

            body: {
              reportID: reportID,
              activities: [activity],
            },
          };
          const response = await API.post(apiName, path, options);
          await dispatch({
            type: CRA_REPORTS_ACTIVITYLOG_SUCCESS,
            data: response.data,
          });
          resolve(response);
        } catch (error) {
          if (error?.response?.status === 401) {
            await dispatch(updateAuthToken());
            await dispatch(activityLogCRAPayload({ reportID, activity, comment }));
          } else {
            await dispatch({
              type: CRA_REPORTS_ACTIVITYLOG_FAILURE,
              data: { error: error },
            });
            reject(error);
          }
        }
      })();
    });
  };
};

export const dismissReportActionError = () => {
  return {
    type: CRA_REPORTS_ACTIVITYLOG_DISMISS_ERROR,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case CRA_REPORTS_ACTIVITYLOG_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        // reportDetails: null,
        reportActionResult: null,
        //loading: true,
        reportActionPending: true,
        reportActionError: null,
      };

    case CRA_REPORTS_ACTIVITYLOG_SUCCESS:
      // The request is success
      return {
        ...state,
        reportActionResult: action.data,
        loading: false,
        reportActionPending: false,
        reportActionError: null,
      };

    case CRA_REPORTS_ACTIVITYLOG_FAILURE:
      // The request is failed
      return {
        ...state,
        loading: false,
        reportActionPending: false,
        reportActionError: action.data.error,
      };

    case CRA_REPORTS_ACTIVITYLOG_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loading: false,
        reportActionError: null,
      };

    default:
      return state;
  }
};
