import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Hub } from 'aws-amplify';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from 'reactstrap';
import { checkUser, toggleSideMenu, signOut } from './redux/actions';
import craLogo_test from '../../images/CRALogo_Test.png';
import craLogo_prod from '../../images/CRALogo.png';
import './App.scss';
/*
  This is the root component of your app. Here you define the overall layout
  and the container of the react router.
  You should adjust it according to the requirement of your app.
*/
const CRA_LOGO =
  process.env.NODE_ENV === 'development'
    ? craLogo_test
    : process.env.REACT_APP_STAGE === 'development'
    ? craLogo_test
    : craLogo_prod;

export const App = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux - States
  const home = useSelector(state => state.home);
  const search = useSelector(state => state.router.location.search);
  const sideMenu = useSelector(state => state.report.sideMenu);

  // State Variables
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  const updateWindowDimensions = () =>
    setWindowDimensions(prev => ({
      ...prev,
      width: window.innerWidth,
      height: window.innerHeight,
    }));

  const getUserName = () =>
    home.username.split(/CRADashboard_(.*?)@/).filter(el => el.trim() !== '')[0];

  const listener = data => {
    switch (data.payload.event) {
      case 'tokenRefresh':
        break;
      case 'cognitoHostedUI':
        break;
      case 'signIn':
        if (!home.checkUserPending) dispatch(checkUser());
        break;
      default:
        dispatch(checkUser());
    }
  };

  Hub.listen('auth', listener);

  useEffect(() => {
    if (search.indexOf('?code') === -1) dispatch(checkUser());
    const values = queryString.parse(location.search);
    if (values.reportID) {
      let reportDetails = { reportID: values.reportID, time: Date.now() };
      localStorage.setItem('reportDetails', JSON.stringify(reportDetails));
    }
    localStorage.setItem('dateRange', null);

    updateWindowDimensions();
    window.addEventListener('resize', () => updateWindowDimensions());
    return () => {
      window.removeEventListener('resize', () => updateWindowDimensions());
    };
  }, []);

  useEffect(() => {
    if (home && !home.username) {
      if (location.pathname != '/' && location.pathname != '' && location.pathname != '/login') {
        history.push('/');
      }
    }
  }, [home]);

  useEffect(() => {
    if (windowDimensions.width > 1024) {
      if (sideMenu) {
        dispatch(toggleSideMenu());
      }
    }
  }, [windowDimensions]);

  return (
    <div className="home-app mw-100">
      <div
        className="dish-header mw-100"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {home.username && (
            <div className="isResponsiveMode">
              <div
                className="hamburger"
                style={{ justifyContent: 'center', alignItems: 'center', width: 50 }}>
                <a onClick={() => dispatch(toggleSideMenu())}>
                  <i
                    className={`fa fa-${sideMenu ? 'times' : 'bars'} ${
                      windowDimensions.width > 800 ? 'fa-3x' : 'fa-2x'
                    } colorsGrey`}
                    aria-hidden="true"></i>
                </a>
              </div>
            </div>
          )}
          <div key={`row-2`} className="col-logo">
            <Link to="/">
              <img
                className="dish-logo greeting"
                height="121"
                width="350"
                alt="CRA logo Test Logo"
                src={CRA_LOGO}
              />
            </Link>
          </div>
          <div />
        </div>
        <div>
          {home.username && windowDimensions.width > 1024 && (
            <div className="my-auto">
              <div>
                <div>
                  <p className="proximaNovaExtraBold20 colorsLightGrey">Signed in as </p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div
                    style={{ marginRight: '20px' }}
                    className="proximaNovaExtraBold28 colorsGrey">
                    {getUserName()}
                  </div>
                  <Button
                    className="btn btn-danger"
                    style={{ width: 100, marginBottom: 0 }}
                    onClick={() => dispatch(signOut())}>
                    Sign out
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="page-container">{props.children}</div>
    </div>
  );
};
