import React, { Fragment, useState, useEffect } from 'react';
import { ReportConstants } from './ReportConstants';
import { ReportImage, PinEntryPopUp } from '../../common';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { FilePicker } from '../../../common/FileUpload';
import { maskImage } from '../redux/queryCRAReportDetails';

export const ThirdPartyProperty = props => {
  const dispatch = useDispatch();
  const [hiddenImages, setHiddenImages] = useState(props.maskedImages ?? []);
  const [showModal, setShowModal] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState('');
  const [incorrectPIN, setIncorrectPIN] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState('');
  const [isDisable, setIsDisable] = useState(false);

  const submitButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    onSubmitPIN();
  };

  const cancelButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
  };

  const onSubmitPIN = () => {
    let trimmed_path = getTrimmedImagePath(selectedImagePath);
    let images = hiddenImages;
    let isToHide = false;
    if (images.includes(trimmed_path)) {
      images.splice(images.indexOf(trimmed_path), 1);
    } else {
      images.push(trimmed_path);
      isToHide = true;
    }
    setHiddenImages(images);
    dispatch(
      maskImage({
        reportID: props.reportId,
        payload: {
          imageUrl: trimmed_path,
          mask: isToHide,
        },
      }),
    );
  };

  const hideUnhideImage = image_path => {
    setSelectedImagePath(image_path);
    setShowModal(true);
  };

  const getTrimmedImagePath = image_path => {
    let ret_val = null;
    if (image_path && image_path.indexOf('?') !== -1) {
      ret_val = image_path.slice(0, image_path.indexOf('?'));
      ret_val = ret_val.slice(ret_val.indexOf('/protected') + 1, ret_val.length);
    }
    return ret_val;
  };

  const isHidden = image_path => hiddenImages.includes(getTrimmedImagePath(image_path));

  useEffect(() => {
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisable(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);

  const inputJson = props.jsondata;
  let count = 0;
  if (inputJson !== null && inputJson != 'undefined' && typeof inputJson === 'object') {
    count = Object.keys(inputJson).length;
  }

  const HideIcon = require('../../../images/hide_icon.png');
  const UnHideIcon = require('../../../images/unhide_icon.png');
  const HiddenImage = require('../../../images/hidden_image.png');

  return (
    <Fragment>
      <div className="report_main_section small-screen-text-center">
        <span className="p4-black"> Third Party Property ({count})</span>
        <br />
        <div>
          {inputJson ? (
            inputJson.map((item, key) => (
              <div className="div-right">
                <Row>
                  <Col>
                    <p className="p4-black-sub-header">Property Info - {key + 1}</p>
                    {item.description ? (
                      <div>
                        <table className="table-details">
                          <tr>
                            <td className="p4-black-light">Description</td>
                            <td className="p4-black-light">
                              {item.description ? item.description : '---'}
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row lg="2" xs="1" className="raw-img">
                  <Col className="col-img">
                    <div className="col-img row text-center vehicle-image-div ">
                      <h6 className="font-weight-light">Close Image 1</h6>
                      {/* <ReportImage className="img-display" src={item.upCloseImage1} alt="" /> */}
                      {isHidden(item.upCloseImage1) && hiddenImages.length > 0 ? (
                        <img className="img-display" src={HiddenImage} alt="" />
                      ) : (
                        <ReportImage className="img-display" src={item.upCloseImage1} alt="" />
                      )}
                      {item.upCloseImage1 ? (
                        <img
                          src={isHidden(item.upCloseImage1) ? UnHideIcon : HideIcon}
                          onClick={() => hideUnhideImage(item.upCloseImage1)}
                          className="hide-unhide-icon"></img>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </Col>
                  <Col className="col-img">
                    <div className="col-img row text-center vehicle-image-div ">
                      <h6 className="font-weight-light">Close Image 2</h6>
                      {/* <ReportImage className="img-display" src={item.upCloseImage2} alt="" /> */}
                      {isHidden(item.upCloseImage2) && hiddenImages.length > 0 ? (
                        <img className="img-display" src={HiddenImage} alt="" />
                      ) : (
                        <ReportImage className="img-display" src={item.upCloseImage2} alt="" />
                      )}
                      {item.upCloseImage2 ? (
                        <img
                          src={isHidden(item.upCloseImage2) ? UnHideIcon : HideIcon}
                          onClick={() => hideUnhideImage(item.upCloseImage2)}
                          className="hide-unhide-icon"></img>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row lg="2" xs="1">
                  <Col className="col-img">
                    <div className="col-img row text-center vehicle-image-div ">
                      <h6 className="font-weight-light">Full View Image 1</h6>
                      {/* <ReportImage className="img-display" src={item.fullViewImage1} alt="" /> */}
                      {isHidden(item.fullViewImage1) && hiddenImages.length > 0 ? (
                        <img className="img-display" src={HiddenImage} alt="" />
                      ) : (
                        <ReportImage className="img-display" src={item.fullViewImage1} alt="" />
                      )}
                      {item.fullViewImage1 ? (
                        <img
                          src={isHidden(item.fullViewImage1) ? UnHideIcon : HideIcon}
                          onClick={() => hideUnhideImage(item.fullViewImage1)}
                          className="hide-unhide-icon"></img>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </Col>
                  <Col className="col-img">
                    <div className="col-img row text-center vehicle-image-div ">
                      <h6 className="font-weight-light">Full View Image 2</h6>
                      {/* <ReportImage className="img-display" src={item.fullViewImage2} alt="" /> */}
                      {isHidden(item.fullViewImage2) && hiddenImages.length > 0 ? (
                        <img className="img-display" src={HiddenImage} alt="" />
                      ) : (
                        <ReportImage className="img-display" src={item.fullViewImage2} alt="" />
                      )}
                      {item.fullViewImage2 ? (
                        <img
                          src={isHidden(item.fullViewImage2) ? UnHideIcon : HideIcon}
                          onClick={() => hideUnhideImage(item.fullViewImage2)}
                          className="hide-unhide-icon"></img>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <div>
              <span className="h5-heading">Third party property details not found</span>
            </div>
          )}
        </div>
        {ReportConstants.displayInputJson ? (
          <div>
            <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
          </div>
        ) : (
          ''
        )}
      </div>
      <Col className="mobileUpload" style={{ marginTop: '3%' }}>
        <FilePicker
          screenName={'property'}
          reportDetails={props.reportDetails}
          isLoading={stat => props.isLoading(stat)}
          updateReportDetails={props.updateReportDetails}
          isDisabled={
            props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisable
          }
        />
      </Col>
      <PinEntryPopUp
        onHide={() => cancelButtonTapped()}
        show={showModal}
        setPIN={text => setEnteredPIN(text)}
        pinError={incorrectPIN}
        onSubmit={() => submitButtonTapped()}
      />
    </Fragment>
  );
};
