import React from 'react';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import { UploadList } from './UploadList';
import { colList } from './Constant';

export const FileType = props => {
  const {
    title,
    files,
    isFreshUpload = true,
    showOpenFileDlg,
    inputOpenFileRef,
    onInputClick,
    onChangeFile,
    deleteFile,
    handleOnUpload,
    downloadFile,
    isDisabled = false,
  } = props;
  return (
    <React.Fragment>
      {!isDisabled ? (
        <div style={{ marginBottom: 30 }}>
          <span className="p4-black-fileUpload">{title}</span>
          <div
            style={{
              border: '1px solid grey',
              overflowX: 'auto',
              width: '70%',
              marginLeft: '10px',
              marginTop: '5px',
            }}>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {isFreshUpload && (
                <Row>
                  <div style={{ background: '', display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        background: '',
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      <Col>
                        <input
                          ref={inputOpenFileRef}
                          type="file"
                          style={{ display: 'none' }}
                          onClick={event => onInputClick(event)}
                          onChange={event => onChangeFile(event)}
                        />
                        <Button
                          variant="secondary"
                          style={{ marginTop: '25%' }}
                          onClick={showOpenFileDlg}>
                          {'Select File(s) to Upload'}
                        </Button>
                      </Col>
                    </div>
                  </div>
                </Row>
              )}
              <Row>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {files.length > 0 && (
                    <UploadList
                      files={files}
                      deleteFile={file => deleteFile(file)}
                      colListToBeRendered={colList}
                      isFreshUpload={isFreshUpload}
                      downloadFile={downloadFile}
                    />
                  )}
                  {files.length > 0 && isFreshUpload && (
                    <Button
                      variant="primary"
                      style={{ marginTop: '6%', alignSelf: 'center' }}
                      onClick={handleOnUpload}>
                      {'Upload'}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};
