import React, { Fragment, useState, useEffect } from 'react';
import { ReportConstants } from './ReportConstants';
import { ReportImage } from '../../common';
import { Row, Col } from 'reactstrap';
import { FilePicker } from '../../../common/FileUpload';
import './report.scss';

export const Police = props => {
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisable(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);

  const inputJson = props.jsondata;

  return (
    <Fragment>
      <div className="report_main_section">
        <span className="p4-black">Police</span>
        <Row>
          <Col>
            <div>
              {inputJson ? (
                <div>
                  <table className="table-details">
                    <tbody>
                      <tr>
                        <td className="p4-black-light">Was Police Called/Contacted?</td>
                        <td className="p4-black-light">{inputJson.isPoliceCheck ? 'Yes' : 'No'}</td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Is Citation Issued?</td>
                        <td className="p4-black-light">
                          {inputJson.isCitationIssued ? 'Yes' : 'No'}
                        </td>
                      </tr>
                      {inputJson.isCitationIssued && inputJson.citationType ? (
                        <tr>
                          <td className="p4-black-light">Who Received Citation?</td>
                          <td className="p4-black-light">{inputJson.citationType}</td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )}
                      <tr>
                        <td className="p4-black-light">Citation</td>
                        <td className="p4-black-light">
                          {inputJson.citation ? inputJson.citation : '---'}
                        </td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Report Number</td>
                        <td className="p4-black-light">
                          {inputJson.reportNumber ? inputJson.reportNumber : '---'}
                        </td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Jurisdiction</td>
                        <td className="p4-black-light">
                          {inputJson.jurisdiction ? inputJson.jurisdiction : '---'}
                        </td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Officer</td>
                        <td className="p4-black-light">
                          {inputJson.officer ? inputJson.officer : '---'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {inputJson.policeImage ? (
                    <div>
                      <br />
                      Police Image
                      <br />
                      <ReportImage src={inputJson.policeImage} alt="Image not found" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div>
                  <span className="h5-heading">Police details not found</span>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {ReportConstants.displayInputJson ? (
          <div>
            <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
          </div>
        ) : (
          ''
        )}
      </div>
      <Col className="mobileUpload" style={{ marginTop: '1' }}>
        <FilePicker
          screenName={'police'}
          reportDetails={props.reportDetails}
          isLoading={stat => props.isLoading(stat)}
          updateReportDetails={props.updateReportDetails}
          isDisabled={
            props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisable
          }
        />
      </Col>
    </Fragment>
  );
};
