import React from 'react';
import { Circles } from 'react-loader-spinner';

export const LoadingView = ({ backdrop = false }) => {
  if (backdrop) {
    return (
      <div
        className="bg-backdrop"
        style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Circles color="#fc0345" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="bg-transparent" style={{ display: 'flex' }}>
      <div
        className="loader"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Circles color="#fc0345" height={80} width={80} />
        <p className="responsive-loading">Loading...</p>
      </div>
    </div>
  );
};
