import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { TabContent, TabPane } from 'reactstrap';
import { checkUser, queryCRAReports, toggleSideMenu, signOut } from './redux/actions';
import { postBusinessLine } from '../../fetchBusinessLine';
import { PinScreen } from './PinScreen';
import { TabularReport } from './TabularReport';

export const DefaultPage = props => {
  const dispatch = useDispatch();

  const sideMenu = useSelector(state => state.report.sideMenu);

  const reportRenderItems = [
    { name: 'Report', component: <TabularReport {...props} s /> },
    { name: 'Business Line & Region' },
    /*,{ name: "Dashboard", component: <StatisticReport {...this.props} /> } Commented it for prod*/
  ];

  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [activeTab, setActiveTab] = useState(0);
  const [businesRegion, setBusinessRegion] = useState({});
  const [pinScreen, setPinScreen] = useState(false);

  const updateWindowDimensions = () =>
    setWindowDimensions(prev => ({
      ...prev,
      width: window.innerWidth,
      height: window.innerHeight,
    }));

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (sideMenu) {
        dispatch(toggleSideMenu());
      }
    }
  };

  const businessLine = value => {
    postBusinessLine(value).then(res => {
      let dateRange = JSON.parse(localStorage.getItem('dateRange'));
      if (!dateRange) {
        const d = new Date();
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth();
        const day = d.getUTCDate();
        const startHour = Date.UTC(year, month, day, 0, 0, 0, 0);
        const endHour = startHour + 86400000;
        dateRange = [new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), new Date(endHour)];
      }
      dispatch(queryCRAReports({ dateRange }));
    });
  };

  const renderSideMenu = reportRenderItems => {
    return (
      <div className={'bg-light sidenav border-right leftmenuwidth'} id="sidebar-wrapper">
        <div className={'list-group list-group-flush leftmenuwidth'}>
          {reportRenderItems.map((item, key) => (
            <React.Fragment key={`reportRenderItem-fragment-${key + 1}`}>
              <a
                href="#"
                key={item.name}
                onClick={() =>
                  item.name === 'Business Line & Region' ? setPinScreen(true) : toggle(item.name)
                }
                className={
                  'list-group-item list-group-item-action ' +
                  (activeTab === item.name ? 'active' : 'bg-light')
                }>
                {item.name}
              </a>
            </React.Fragment>
          ))}
          <span className="display-small-screen">
            <a
              href="#"
              onClick={() => dispatch(signOut())}
              className="list-group-item list-group-item-action bg-light">
              <strong>
                <i className="fa fa-power-off"></i>&nbsp; Sign Out
              </strong>
            </a>
          </span>
        </div>
        <div className="filter-container"></div>
      </div>
    );
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', () => updateWindowDimensions());
    return () => {
      window.removeEventListener('resize', () => updateWindowDimensions());
    };
  }, []);

  useEffect(() => {
    if (activeTab == '') {
      toggle(reportRenderItems[0].name);
    }
  }, [activeTab]);

  return (
    <div>
      <div className="d-flex" id="wrapper">
        {
          sideMenu
            ? windowDimensions.width < 1024 && renderSideMenu(reportRenderItems)
            : windowDimensions.width > 1024 && renderSideMenu(reportRenderItems)
          // <div className={"bg-light sidenav border-right leftmenuwidth"} id="sidebar-wrapper">
          //     <div className={"list-group list-group-flush leftmenuwidth"}>
          //         {
          //             this.reportRenderItems.map((item, key) =>
          //                     <a href="#" key={item.name} onClick={() => this.toggle(item.name)} className={"list-group-item list-group-item-action "+(this.state.activeTab === item.name ? "active" : "bg-light")}>{item.name}</a>
          //                     )
          //                 }
          //                 <span className='display-small-screen'>
          //                     <a href="#" onClick={this.signOut} className="list-group-item list-group-item-action bg-light"><strong><i className="fa fa-power-off"></i>&nbsp; Sign Out</strong></a>
          //                 </span>
          //     </div>
          //     <div className="filter-container">
          //     </div>
          //     </div>
        }
        <TabContent className="submenuBody" activeTab={activeTab}>
          {reportRenderItems.map((item, key) => (
            <TabPane key={`reportRenderItem-${key + 1}`} tabId={item.name}>
              {item.component}
            </TabPane>
          ))}
        </TabContent>
      </div>
      {pinScreen && (
        <PinScreen
          businesRegion={value => {
            setBusinessRegion(value);
            setPinScreen(false);
            businessLine(value);
          }}
          isPinScreen={value => setPinScreen(false)}
        />
      )}
    </div>
  );
};
