import React, { useState, useEffect } from 'react';
import './ReportImage.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const ReportImage = ({ promise = null, src = null }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const [seen, setSeen] = useState(false);

  const togglePop = () => {
    setSeen(prev => !prev);
  };

  useEffect(() => {
    if (promise) {
      promise
        .then(result => {
          setImageUrl(result);
        })
        .catch(err => console.log(err));
    }
    if (src) {
      setImageUrl(src);
    }
  }, []);

  if (imageUrl)
    return (
      <div className="common-report-div">
        <div className="common-report-div-2" onClick={() => togglePop()}>
          <img className="common-report-image" src={imageUrl} crossOrigin="anonymous" />
        </div>

        <Modal isOpen={seen} fade={false} backdrop={false} toggle={() => togglePop()}>
          <ModalHeader className="modalheader">
            {imageName}
            <a href={imageUrl} download target="_blank">
              Open in a new Tab
            </a>
            <button className="closebutton" onClick={() => togglePop()}>
              Close
            </button>
          </ModalHeader>
          <ModalBody>
            <img className="common-report-image-full" src={imageUrl} />
          </ModalBody>
        </Modal>
      </div>
    );
  return <div className="common-report-div no-image-div">No Image</div>;
};
