import { Dropdown, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Region, BusinessLine } from '../common/BusinessLineConstant';

export const DropDown = props => {
  const [show, setShow] = useState(true);
  const [business, setBusiness] = useState('All');
  const [region, setRegion] = useState('Select');
  const [cachedRegion, setCachedRegion] = useState(localStorage.getItem('userBusiness'));

  useEffect(() => {
    if (cachedRegion === BusinessLine.DBS) {
      setShow(false);
    }
  });

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>Business Line</Form.Label>
        <Form.Control
          as="select"
          defaultValue={localStorage.getItem('userBusiness')}
          onChange={event => {
            if (event.target.value === BusinessLine.DBS) {
              setShow(false);
            } else {
              setShow(true);
            }
            props.onBusiness(event.target.value);
          }}>
          <option>{BusinessLine.ALL}</option>
          <option>{BusinessLine.BOOST}</option>
          <option>{BusinessLine.DBS}</option>
          <option>{BusinessLine.WIRELESS}</option>
        </Form.Control>

        <Form.Label>Region</Form.Label>
        <Form.Control
          as="select"
          disabled={show}
          defaultValue={localStorage.getItem('userRegion')}
          onChange={event => props.onRegion(event.target.value)}>
          <option>{Region.SELECT}</option>
          <option>{Region.MIDWEST}</option>
          <option>{Region.NORTH_EAST}</option>
          <option>{Region.SOUTHEAST}</option>
          <option>{Region.WEST}</option>
          <option>{Region.SOUTH_CENTRAL}</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
};
