import { DefaultPage } from './';

export const homeRoute = {
  path: '/',
  name: 'Home',
  childRoutes: [
    {
      path: 'default-page',
      name: 'Default page',
      component: DefaultPage,
      isIndex: true,
    },
  ],
};

/*export default {
  path: 'ota-users-panel',
  name: 'Ota users panel',
  auth: true,
  component: DefaultPage,
  childRoutes: [
    { path: 'default-page', name: 'Default page', component: DefaultPage, isIndex: true },
  ],
};*/
