// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { DefaultPage } from './';

export const loginRoute = {
  path: 'login',
  name: 'Login',
  noAuth: true,
  childRoutes: [
    {
      path: 'default-page',
      name: 'Default page',
      component: DefaultPage,
      isIndex: true,
      noAuth: true,
    },
  ],
};
