import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { ReportConstants } from './ReportConstants';
import { FilePicker } from '../../../common/FileUpload';

const K_WIDTH = 20;
const K_HEIGHT = 20;

const MyMarkerPlace = props => {
  return (
    <div
      style={{
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,

        border: '5px solid #f44336',
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 8,
        fontWeight: 'bold',
        padding: 4,
      }}>
      {props.text}
    </div>
  );
};

export const IncidentLocation = props => {
  const [isDisabled, setIsDisabled] = useState(false);
  const inputJson = props.jsondata;
  let location = inputJson.latitude + ',' + inputJson.longitude;
  useEffect(() => {
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisabled(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="incidentmainview">
        <div className="incidentinfo">
          <span className="p4-black">Incident Scene Location</span>
          <br />
          {inputJson ? (
            <div>
              <table className="table-details incidenttable-details">
                <tbody>
                  <tr>
                    <td className="p4-black-light">Address</td>
                    <td className="p4-black-light">{inputJson.address ?? '---'}</td>
                  </tr>
                  <tr>
                    <td className="p4-black-light">Latitude</td>
                    <td className="p4-black-light">{inputJson.latitude ?? '---'}</td>
                  </tr>
                  <tr>
                    <td className="p4-black-light">Longitude</td>
                    <td className="p4-black-light">{inputJson.longitude ?? '---'}</td>
                  </tr>
                </tbody>
              </table>
              <button
                className="btn btn-danger downloadJsonbutton"
                style={{ marginLeft: 10 }}
                onClick={() => window.open('http://maps.google.com/maps?q=' + location, '_blank')}>
                Show on Map
              </button>

              <br />
              <span className="p4-black">Incident Scene Description</span>
              <br />
              <div style={{ marginLeft: '10px' }}>
                <span className="p1-black">
                  Describe in detail, what occurred. Include street names, direction of travel,
                  weather/road conditions, etc
                </span>
              </div>
              <div className="incidenttable-details-onerow" style={{ marginLeft: 10 }}>
                {inputJson.description ?? '---'}
              </div>
              <br />
              <span className="p4-black">What Happened</span>
              <br />
              <div style={{ marginLeft: '10px' }}>
                <span className="p1-black">
                  Describe in your own words, why did the incident occur? What preventive action
                  should have been taken?
                </span>
                <div className="incidenttable-details-onerow">
                  {inputJson.tdbDescription ?? '---'}
                </div>
              </div>

              <br />
            </div>
          ) : (
            <div>
              <br />
              <span className="h5-heading">Incident location details not found</span>
            </div>
          )}
        </div>
        <div>
          {inputJson != null && (
            <div
              className="incidentmap"
              style={{
                height: '50vh',
                // width: '50%',
                marginBottom: 100,
                borderColor: 'grey',
                borderWidth: 1,
                borderStyle: 'solid',
              }}>
              {/* Loading Map */}
              <GoogleMapReact
                options={{ streetViewControl: true, mapTypeControl: true }}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: ReportConstants.googleApiKey }}
                defaultCenter={{
                  lat: inputJson.latitude,
                  lng: inputJson.longitude,
                }}
                onGoogleApiLoaded={({ map, maps }) =>
                  new maps.Circle({
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.3,
                    map,
                    center: { lat: inputJson.latitude, lng: inputJson.longitude },
                    radius: 275,
                  })
                }
                defaultZoom={10} /* 10 means 8 km radius*/
              >
                {/* displaying user selected location */}
                <MyMarkerPlace
                  lat={inputJson.latitude}
                  lng={inputJson.longitude}
                  key={'test'}></MyMarkerPlace>
              </GoogleMapReact>
            </div>
          )}
        </div>
        {ReportConstants.displayInputJson ? (
          <div>
            <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="mobileUpload" style={{ marginLeft: 10 }}>
        <FilePicker
          screenName={'incidentlocation'}
          reportDetails={props.reportDetails}
          isLoading={stat => props.isLoading(stat)}
          updateReportDetails={props.updateReportDetails}
          isDisabled={
            props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisabled
          }
        />
      </div>
    </React.Fragment>
  );
};
