import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { DropDown } from '../home';

export const PinScreen = props => {
  const [show, setShow] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [pinValue, setPinValue] = useState('');
  const [business, setBusiness] = useState('All');
  const [region, setRegion] = useState('Select');

  const handleClose = () => {
    setShow(false);
    setPinValue('');
    setIsDisabled(true);
    localStorage.setItem('userBusiness', business);
    localStorage.setItem('userRegion', region);
    props.businesRegion({ business: business, region: region });
  };

  const onBusinessChange = value => setBusiness(value);
  const setRegionChange = value => setRegion(value);

  return (
    <Modal
      show={show}
      onHide={() => {
        props.isPinScreen();
        setShow(false);
      }}
      centered
      animation={false}
      backdrop={false}
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Select Business Line</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DropDown
          onBusiness={value => onBusinessChange(value)}
          onRegion={value => setRegionChange(value)}
        />
      </Modal.Body>

      <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button variant="danger" disabled={isDisabled} onClick={handleClose}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
