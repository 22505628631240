import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ReportConstants } from './ReportConstants';
import { ReportImage, PinEntryPopUp } from '../../common';
import { Row, Col } from 'reactstrap';
import { FilePicker } from '../../../common/FileUpload';
import { maskImage } from '../redux/queryCRAReportDetails';

export const IncidentSceneReport = props => {
  const dispatch = useDispatch();
  const [hiddenImages, setHiddenImages] = useState(props.maskedImages ?? []);
  const [showModal, setShowModal] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState('');
  const [incorrectPIN, setIncorrectPIN] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const submitButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    onSubmitPIN();
  };

  const cancelButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
  };

  const onSubmitPIN = () => {
    let trimmed_path = getTrimmedImagePath(selectedImagePath);
    let images = hiddenImages;
    let isToHide = false;
    if (images.includes(trimmed_path)) {
      images.splice(images.indexOf(trimmed_path), 1);
    } else {
      images.push(trimmed_path);
      isToHide = true;
    }
    setHiddenImages(images);
    dispatch(
      maskImage({
        reportID: props.reportId,
        payload: {
          imageUrl: trimmed_path,
          mask: isToHide,
        },
      }),
    );
  };

  const hideUnhideImage = image_path => {
    setSelectedImagePath(image_path);
    setShowModal(true);
  };

  const getTrimmedImagePath = image_path => {
    let ret_val = null;
    if (image_path && image_path.indexOf('?') !== -1) {
      ret_val = image_path.slice(0, image_path.indexOf('?'));
      ret_val = ret_val.slice(ret_val.indexOf('/protected') + 1, ret_val.length);
    }
    return ret_val;
  };

  const isHidden = image_path => hiddenImages.includes(getTrimmedImagePath(image_path));

  useEffect(() => {
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisabled(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);

  const inputJson = props.jsondata;

  const HideIcon = require('../../../images/hide_icon.png');
  const UnHideIcon = require('../../../images/unhide_icon.png');
  const HiddenImage = require('../../../images/hidden_image.png');

  return (
    <Fragment>
      <div className="report_main_section small-screen-text-center">
        <span className="p4-black"> Incident Scene Report</span>
        <br />
        <div>
          {inputJson ? (
            <div className="div-right">
              <Row lg="2" xs="1" className="raw-img">
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div ">
                    <h6 className="font-weight-light">Incident Full view 1</h6>
                    {isHidden(inputJson.incidentFullview1) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.incidentFullview1}
                        alt=""
                      />
                    )}
                    {inputJson.incidentFullview1 ? (
                      <img
                        src={isHidden(inputJson.incidentFullview1) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.incidentFullview1)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div ">
                    <h6 className="font-weight-light">Incident Up close Image 1</h6>
                    {/* <ReportImage className="img-display" src={inputJson.incidentUpcloseImage1} alt="" /> */}
                    {isHidden(inputJson.incidentUpcloseImage1) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.incidentUpcloseImage1}
                        alt=""
                      />
                    )}
                    {inputJson.incidentUpcloseImage1 ? (
                      <img
                        src={isHidden(inputJson.incidentUpcloseImage1) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.incidentUpcloseImage1)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
              </Row>
              <Row lg="2" xs="1" className="raw-img">
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div ">
                    <h6 className="font-weight-light">Incident Full view 2</h6>
                    {/* <ReportImage className="img-display" src={inputJson.incidentFullview2} alt="" /> */}
                    {isHidden(inputJson.incidentFullview2) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.incidentFullview2}
                        alt=""
                      />
                    )}
                    {inputJson.incidentFullview2 ? (
                      <img
                        src={isHidden(inputJson.incidentFullview2) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.incidentFullview2)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div ">
                    <h6 className="font-weight-light">Incident Up close Image2</h6>
                    {/* <ReportImage className="img-display" src={inputJson.incidentUpcloseImage2} alt="" /> */}
                    {isHidden(inputJson.incidentUpcloseImage2) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.incidentUpcloseImage2}
                        alt=""
                      />
                    )}
                    {inputJson.incidentUpcloseImage2 ? (
                      <img
                        src={isHidden(inputJson.incidentUpcloseImage2) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.incidentUpcloseImage2)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <span className="h5-heading">Incident scene report details not found</span>
            </div>
          )}
        </div>
        {ReportConstants.displayInputJson ? (
          <div>
            <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
          </div>
        ) : (
          ''
        )}
      </div>
      <Col className="mobileUpload" style={{ marginTop: '1%' }}>
        <FilePicker
          screenName={'incident'}
          reportDetails={props.reportDetails}
          isLoading={stat => props.isLoading(stat)}
          updateReportDetails={props.updateReportDetails}
          isDisabled={
            props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisabled
          }
        />
      </Col>
      <PinEntryPopUp
        onHide={() => cancelButtonTapped()}
        show={showModal}
        setPIN={text => setEnteredPIN(text)}
        pinError={incorrectPIN}
        onSubmit={() => submitButtonTapped()}
      />
    </Fragment>
  );
};
