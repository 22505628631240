import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const AuthRoute = ({ pathRender, ...rest }) => {
  const username = useSelector(state => state.home.username);
  return (
    <Route
      {...rest}
      render={props =>
        username ? (
          pathRender(props)
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};
