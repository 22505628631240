import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { ReportConstants } from './ReportConstants';
import { ReportImage, PinEntryPopUp } from '../../common';
import DashboardImage from '../../../images/DashboardImage.png';
import { FilePicker } from '../../../common/FileUpload';
import { maskImage } from '../redux/queryCRAReportDetails';

export const CompanyVehicle = props => {
  const dispatch = useDispatch();
  const [hiddenImages, setHiddenImages] = useState(props.maskedImages ?? []);
  const [showModal, setShowModal] = useState(false);
  const [incorrectPIN, setIncorrectPIN] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const submitButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    onSubmitPIN();
  };

  const cancelButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
  };

  const onSubmitPIN = () => {
    const trimmed_path = getTrimmedImagePath(selectedImagePath);
    let images = hiddenImages;
    let isToHide = false;
    if (images.includes(trimmed_path)) {
      images.splice(images.indexOf(trimmed_path), 1);
    } else {
      images.push(trimmed_path);
      isToHide = true;
    }
    setHiddenImages(images);
    dispatch(
      maskImage({
        reportID: props.reportId,
        payload: {
          imageUrl: trimmed_path,
          mask: isToHide,
        },
      }),
    );
  };

  const hideUnhideImage = image_path => {
    setSelectedImagePath(image_path);
    setShowModal(true);
  };

  const getTrimmedImagePath = image_path => {
    let ret_val = null;
    if (image_path && image_path.indexOf('?') !== -1) {
      ret_val = image_path.slice(0, image_path.indexOf('?'));
      ret_val = ret_val.slice(ret_val.indexOf('/protected') + 1, ret_val.length);
    }
    return ret_val;
  };

  const isHidden = image_path => hiddenImages.includes(getTrimmedImagePath(image_path));

  const inputJson = props.jsondata;

  const HideIcon = require('../../../images/hide_icon.png');
  const UnHideIcon = require('../../../images/unhide_icon.png');
  const HiddenImage = require('../../../images/hidden_image.png');

  useEffect(() => {
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisabled(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);

  return (
    <Fragment>
      <div className="report_main_section">
        <span className="p4-black">Company Vehicle</span>
        <br />
        <div>
          {inputJson ? (
            <div className="div-right">
              <div style={{ marginLeft: 10 }}>
                <span className="p4-black-light-company-vehicle">
                  {`Was the company vehicle towed away from the incident scene? ${
                    inputJson.vanDamageTowingInformation &&
                    inputJson.vanDamageTowingInformation.isVehicleTowed
                      ? ' Yes'
                      : ' No'
                  }`}
                </span>
                <br />
              </div>
              <span className="p4-black">Areas where the vehicle was damaged</span>
              <Row lg="3" xs="1" className="raw-img">
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle Front Left</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageFrontLeftPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageFrontLeftPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageFrontLeftPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageFrontLeftPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageFrontLeftPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageFrontLeftPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div ">
                    <h6 className="font-weight-light">Vehicle Front</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageFrontPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageFrontPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageFrontPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageFrontPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageFrontPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageFrontPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div ">
                    <h6 className="font-weight-light">Vehicle Front Right</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageFrontRightPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageFrontRightPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageFrontRightPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageFrontRightPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageFrontRightPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageFrontRightPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
              </Row>
              <Row lg="3" xs="1" className="raw-img">
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle left</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageLeftPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageLeftPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageLeftPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageLeftPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageLeftPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageLeftPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
                <Col className="hide-small-screen">
                  <div className="col-img">
                    <img
                      className="col-img center-img-display"
                      src={DashboardImage}
                      alt="Dish vehicle Image"
                    />
                  </div>
                </Col>
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle Right</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageRightPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageRightPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageRightPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageRightPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageRightPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageRightPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
              </Row>
              <Row lg="3" xs="1" className="raw-img">
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle Rear left</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageRearLeftPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageRearLeftPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageRearLeftPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageRearLeftPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageRearLeftPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageRearLeftPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>

                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle Rear</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageRearPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageRearPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageRearPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageRearPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageRearPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageRearPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>

                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle Rear right</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageRearRightPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageRearRightPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage
                        className="img-display"
                        src={inputJson.vanDamageRearRightPic}
                        alt=""
                      />
                    )}
                    {inputJson.vanDamageRearRightPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageRearRightPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageRearRightPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
              </Row>
              <Row lg="3" className="raw-img">
                <Col className="hide-small-screen"></Col>
                <Col className="col-img">
                  <div className="col-img row text-center vehicle-image-div">
                    <h6 className="font-weight-light">Vehicle Top</h6>
                    {/* <ReportImage className="img-display" src={inputJson.vanDamageTopPic} alt="" /> */}
                    {isHidden(inputJson.vanDamageTopPic) && hiddenImages.length > 0 ? (
                      <img className="img-display" src={HiddenImage} alt="" />
                    ) : (
                      <ReportImage className="img-display" src={inputJson.vanDamageTopPic} alt="" />
                    )}
                    {inputJson.vanDamageTopPic ? (
                      <img
                        src={isHidden(inputJson.vanDamageTopPic) ? UnHideIcon : HideIcon}
                        onClick={() => hideUnhideImage(inputJson.vanDamageTopPic)}
                        className="hide-unhide-icon"></img>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
                <Col className="hide-small-screen"></Col>
              </Row>
            </div>
          ) : (
            <div>
              <span className="h5-heading">Company vehicle details not found</span>
            </div>
          )}
        </div>
        {ReportConstants.displayInputJson ? (
          <div>
            <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="mobileUpload">
        <Col style={{ marginTop: '25%' }}>
          <FilePicker
            screenName={'companyVehicle'}
            reportDetails={props.reportDetails}
            isLoading={stat => props.isLoading(stat)}
            updateReportDetails={props.updateReportDetails}
            isDisabled={
              props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisabled
            }
          />
        </Col>
        <PinEntryPopUp
          onHide={() => cancelButtonTapped()}
          show={showModal}
          setPIN={text => setIncorrectPIN(text)}
          pinError={incorrectPIN}
          onSubmit={() => submitButtonTapped()}
        />
      </div>
    </Fragment>
  );
};
