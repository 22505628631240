import React from 'react';
import moment from 'moment';
import './flowchart.scss';
import file_upload_success from '../../../images/file_upload_success.png';
import file_upload_partial from '../../../images/file_upload_partial.png';
import api_icon from '../../../images/cloud_api_icon.png';
import sftp_icon from '../../../images/sftp_icon.png';
import email_icon from '../../../images/email_icon.png';
import success_icon from '../../../images/check_mark_icon.png';
import question_icon from '../../../images/question_mark.png';
import failure_icon from '../../../images/close_icon.png';
import info_icon from '../../../images/info_icon.png';
import arrows from '../../../images/Arrows.svg';

export const Flowchart = props => {
  const {
    completeJson,
    fleetApiStatus,
    fleetSFTPStatus,
    fleetEmailStatus,
    handleStatus,
    showModal,
    handleEmail,
    fleetApiList,
    fleetSftpList,
    emailList,
    handlePartialAlert,
    isApiBtnTapped,
    isSFTPBtnTapped,
    isEmailBtnTapped,
    ziplocation,
  } = props;

  return (
    <section className="container">
      <div className="div-wrapper">
        <div
          style={(!completeJson.isReportComplete && { marginTop: '21px' }) || { display: 'block' }}
          className="db-frame">
          <span className="db-title">
            <p style={{ fontWeight: 'bold', fontSize: 'large' }}>Last Insert Time</p>
            <p style={{ marginRight: '20px' }}>
              {moment(completeJson.StatusChangeTime).format('MM/DD/YYYY')}
            </p>
            <p style={{ marginRight: '5px' }}>
              {moment(completeJson.StatusChangeTime).format('hh:mm A Z')}
            </p>
          </span>
          <img
            src={completeJson.isReportComplete ? file_upload_success : file_upload_partial}
            width={290}
          />

          <div className="db-details">
            <p className="db-status">{completeJson.isReportComplete ? 'COMPLETED' : 'PARTIAL'}</p>
            <p style={{ marginBottom: '0px' }}>
              {!completeJson.isReportComplete && (
                <button
                  style={{ marginBottom: '0px', marginTop: '0px' }}
                  className="btn btn-danger btn btn-secondary"
                  onClick={() => showModal(6)}>
                  NOTIFY
                </button>
              )}
            </p>
          </div>
        </div>

        {/* <span style={{ width: '200px' }}></span> */}
        <img src={arrows} height={450} />
        <div className="container__sources">
          <div className="div-wrapper" style={{ padding: '15px 30px 0px 30px' }}>
            <h3 className="main-heading p4-black">Fleet Response</h3>
            <div className="sources--cms tiles">
              <h3 className="heading"> {'API'} </h3>
              <a>
                <img
                  className="status"
                  height={25}
                  src={
                    fleetApiList.length > 0
                      ? fleetApiStatus
                        ? failure_icon
                        : success_icon
                      : question_icon
                  }
                />
              </a>
              <div className="icon">
                <img src={api_icon} style={{ margin: 'auto' }} width={100} height={100} />
              </div>

              <span className="blk">
                <button
                  className={`btn-wf ${
                    isApiBtnTapped
                      ? 'sent'
                      : fleetApiList.length === 0
                      ? 'send'
                      : fleetApiStatus
                      ? 'retry'
                      : 'sent'
                  }`}
                  onClick={() => {
                    `${
                      fleetApiList.length === 0
                        ? handlePartialAlert(true)
                        : fleetApiStatus && fleetApiList.length === 0
                        ? handlePartialAlert(true)
                        : handleStatus(true)
                    }`;
                  }}
                  disabled={isApiBtnTapped ? true : fleetApiList.length > 0 && !fleetApiStatus}>
                  {fleetApiList.length === 0 ? 'SEND' : fleetApiStatus ? 'RETRY' : 'SENT'}
                </button>
                <a>
                  <img
                    className="infoIcon"
                    height={25}
                    src={info_icon}
                    onClick={() => showModal(1)}
                  />
                </a>
              </span>
            </div>

            <div className="sources--markdown tiles">
              {/* <span style={{display: 'contents'}}>  */}

              <h3 className="heading">{'SFTP'}</h3>
              <a>
                <img
                  className="status"
                  height={25}
                  src={
                    fleetSftpList.length > 0
                      ? fleetSFTPStatus
                        ? failure_icon
                        : success_icon
                      : question_icon
                  }
                />
              </a>
              <div className="icon">
                <img src={sftp_icon} style={{ margin: 'auto' }} width={100} height={100} />
              </div>
              {/* </span> */}
              <span className="blk">
                <button
                  className={`btn-wf ${
                    isSFTPBtnTapped || !ziplocation
                      ? 'sent'
                      : fleetSftpList.length === 0
                      ? 'send'
                      : fleetSFTPStatus
                      ? 'retry'
                      : 'sent'
                  }`}
                  onClick={() => {
                    `${
                      fleetSftpList.length === 0
                        ? handlePartialAlert(false)
                        : fleetSFTPStatus
                        ? handlePartialAlert(false)
                        : handleStatus(false)
                    }`;
                  }}
                  disabled={
                    isSFTPBtnTapped || !ziplocation
                      ? true
                      : fleetSftpList.length > 0 && !fleetSFTPStatus
                  }>
                  {!ziplocation
                    ? 'NO IMAGE'
                    : fleetSftpList.length === 0
                    ? 'SEND'
                    : fleetSFTPStatus
                    ? 'RETRY'
                    : 'SENT'}
                </button>{' '}
                <a>
                  <img
                    className="infoIcon"
                    height={25}
                    src={info_icon}
                    onClick={() => showModal(2)}
                  />
                </a>
              </span>
            </div>
          </div>
          <div className="sources--data tiles">
            <h3 className="heading">{'Email'}</h3>
            <a>
              <img
                className="status"
                height={25}
                src={
                  emailList.length > 0
                    ? fleetEmailStatus
                      ? failure_icon
                      : success_icon
                    : question_icon
                }
              />
            </a>
            <div className="icon">
              <img src={email_icon} style={{ margin: 'auto' }} width={100} height={100} />
            </div>
            <span className="blk">
              <button
                className={`btn-wf ${
                  isEmailBtnTapped
                    ? 'sent'
                    : emailList.length === 0
                    ? 'send'
                    : fleetEmailStatus
                    ? 'retry'
                    : 'send'
                }`}
                disabled={isEmailBtnTapped}
                onClick={() => handleEmail(true)}>
                {fleetEmailStatus ? 'RETRY' : 'SEND'}
              </button>
              <a>
                <img
                  className="infoIcon"
                  height={25}
                  src={info_icon}
                  onClick={() => handleEmail(false)}
                />
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
