import {
  CRA_REPORTS_COMPLETE_LIST_BEGIN,
  CRA_REPORTS_COMPLETE_LIST_SUCCESS,
  CRA_REPORTS_COMPLETE_LIST_FAILURE,
  CRA_REPORTS_COMPLETE_LIST_DISMISS_ERROR,
} from './constants';
import { store } from '../../../common/store';
import { REACT_APP_API_KEY } from '../../../aws-exports';
import { API } from 'aws-amplify';

export const queryCRACompleteReports = (reportList, screen = null, type = null) => {
  return async dispatch => {
    dispatch({
      type: CRA_REPORTS_COMPLETE_LIST_BEGIN,
    });
    let promiseList = [];
    reportList != null &&
      reportList.length > 0 &&
      reportList.map((data, index) => {
        promiseList.push(fetchCompleteReportDetails(data.reportID, index));
      });

    Promise.all(promiseList)
      .then(data => {
        dispatch({
          type: CRA_REPORTS_COMPLETE_LIST_SUCCESS,
          data: data,
          screen: screen,
          methodType: type,
        });
      })
      .catch(error => {
        dispatch({
          type: CRA_REPORTS_COMPLETE_LIST_FAILURE,
          data: { error: error },
          screen: screen,
          methodType: type,
        });
      });
  };
};

export const fetchCompleteReportDetails = (reportID, index) => {
  return new Promise((resolve, reject) => {
    const { jwt } = store.getState().home;
    if (jwt) {
      const apiName = 'crareportapi';
      const path = '/cra/getreportdetails';
      const myInit = {
        // OPTIONAL
        headers: {
          Authorization: jwt,
          'x-api-key': REACT_APP_API_KEY,
        },
        queryStringParameters: {
          // OPTIONAL
          reportID: reportID,
        },
      };
      API.get(apiName, path, myInit)
        .then(response => {
          console.log('index: ', index);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    }
  });
};

export const dismissQueryReportError = () => {
  return {
    type: CRA_REPORTS_COMPLETE_LIST_DISMISS_ERROR,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case CRA_REPORTS_COMPLETE_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        reportCompletelist: null,
        reportDetails: null,
        reportActionResult: null,
        queryCRAReportsPending: true,
        queryCRAReportsError: null,
      };

    case CRA_REPORTS_COMPLETE_LIST_SUCCESS:
      // The request is success
      return {
        ...state,
        reportCompletelist: action.data,
        loading: false,
        queryCRAReportsPending: false,
        queryCRAReportsError: null,
        screen: action.screen,
        type: action.methodType,
      };

    case CRA_REPORTS_COMPLETE_LIST_FAILURE:
      // The request is failed
      return {
        ...state,
        loading: false,
        queryCRAReportsPending: false,
        queryCRAReportsError: action.data.error,
      };

    case CRA_REPORTS_COMPLETE_LIST_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loading: false,
        queryCRAReportsError: null,
      };

    default:
      return state;
  }
};
