import React, { useState } from 'react';
import trash from './trash.png';
import download from './download.png';
import { PinEntryPopUp } from '../../features/common';

export const UploadList = props => {
  const [showModal, setShowModal] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState('');
  const [incorrectPIN, setIncorrectPIN] = useState(false);
  const [fileSelected, setFileSelected] = useState('');

  const showPinPopUp = file => {
    setShowModal(true);
    setFileSelected(file);
  };

  const submitButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    props.downloadFile(fileSelected);
  };

  const cancelButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
  };

  return (
    <div className="table-responsive">
      {/* <table className="table"> */}
      <table className="condensed striped hover" id="uploadListTable" style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>{props.isFreshUpload ? 'Name' : 'File Name'}</th>
            <th>{'Size'}</th>
            <th>{props.isFreshUpload ? 'Actions' : 'Uploaded By'}</th>
            {!props.isFreshUpload && <th>{'Timestamp'}</th>}
            {!props.isFreshUpload && <th>{'Actions'}</th>}
          </tr>
        </thead>
        <tbody style={{ marginTop: 20 }}>
          {props.files.map((file, index) => (
            <tr>
              <td>{file['name'] ? file['name'] : 'file'}</td>
              <td>{file['size'] ? file['size'] : '0 KB'}</td>
              <td>
                {props.isFreshUpload ? (
                  <img
                    src={trash}
                    style={{ width: 24, height: 24, cursor: 'pointer' }}
                    alt={'deleteFile'}
                    onClick={() => props.deleteFile(file)}
                  />
                ) : (
                  file['uploaderName']
                )}
              </td>
              {!props.isFreshUpload && <td>{new Date(file['timeOfUpload']).toString()}</td>}
              {!props.isFreshUpload && (
                <td>
                  {
                    <img
                      src={download}
                      style={{ width: 24, height: 24, cursor: 'pointer' }}
                      alt={'downloadFile'}
                      onClick={() => {
                        showPinPopUp(file);
                      }}
                    />
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <PinEntryPopUp
          onHide={cancelButtonTapped}
          show={showModal}
          setPIN={text => setEnteredPIN(text)}
          pinError={incorrectPIN}
          onSubmit={submitButtonTapped}
        />
      </div>
    </div>
  );
};
