import awsExports, { REACT_APP_API_KEY } from './aws-exports';
import { Region, BusinessLine } from '../src/features/common/BusinessLineConstant';
import { store } from './common/store';

export const getBusinessLine = () => {
  return new Promise((resolve, reject) => {
    console.log('In GetBusinessLIne');
    const endpoint_url =
      awsExports.API.endpoints[0].endpoint + '/cra/dashboarduser/userdetails?email=';
    const { email, jwt } = store.getState().home;
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: jwt,
      'x-api-key': REACT_APP_API_KEY,
    });
    fetch(endpoint_url + email, {
      method: 'GET',
      headers: headers,
    })
      .then(response => response.json())
      .then(res => {
        if (res.pin) {
          localStorage.setItem('pin', window.btoa(res.pin));
        } else {
          localStorage.setItem('pin', window.btoa('7233'));
        }
        localStorage.setItem('userBusiness', res.businessLine === '*' ? 'All' : res.businessLine);
        localStorage.setItem('userRegion', res.region === '*' ? 'Select' : res.region);
        resolve(res);
      })
      .catch(err => {
        localStorage.setItem('pin', window.btoa('7233'));
        reject(err);
      });
  });
};

export const postBusinessLine = value => {
  return new Promise((resolve, reject) => {
    console.log('In PostBusinesDetails');
    const payload = {
      businessLine: value.business === BusinessLine.ALL ? '*' : value.business,
      region: value.region === Region.SELECT ? '*' : value.region,
    };
    const { email, jwt } = store.getState().home;
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: jwt,
      'x-api-key': REACT_APP_API_KEY,
    });
    const endpoint_url =
      awsExports.API.endpoints[0].endpoint + '/cra/dashboarduser/userdetails?email=';
    fetch(endpoint_url + email, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(res => {
        //   localStorage.setItem("pin",window.btoa(res.pin))
        resolve(res);
      })
      .catch(err => reject(err));
  });
};
