export const CRA_REPORTS_DETAILS_BEGIN = 'CRA_REPORTS_DETAILS_BEGIN';
export const CRA_REPORTS_DETAILS_SUCCESS = 'CRA_REPORTS_DETAILS_SUCCESS';
export const CRA_REPORTS_DETAILS_FAILURE = 'CRA_REPORTS_DETAILS_FAILURE';
export const CRA_REPORTS_DETAILS_DISMISS_ERROR = 'CRA_REPORTS_DETAILS_DISMISS_ERROR';

export const CRA_REPORTS_ACTIVITYLOG_BEGIN = 'CRA_REPORTS_ACTIVITYLOG_BEGIN';
export const CRA_REPORTS_ACTIVITYLOG_SUCCESS = 'CRA_REPORTS_ACTIVITYLOG_SUCCESS';
export const CRA_REPORTS_ACTIVITYLOG_FAILURE = 'CRA_REPORTS_ACTIVITYLOG_FAILURE';
export const CRA_REPORTS_ACTIVITYLOG_DISMISS_ERROR = 'CRA_REPORTS_ACTIVITYLOG_DISMISS_ERROR';

export const CRA_REPORTS_DETAILS_UPDATE_COMPLETE_REPORT =
  'CRA_REPORTS_DETAILS_UPDATE_COMPLETE_REPORT';

export const SET_CRA_REPORT_DETAILS_RENDERED_REPORT_COMPONENTS =
  'SET_CRA_REPORT_DETAILS_RENDERED_REPORT_COMPONENTS';
