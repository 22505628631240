/* This is the Root component mainly initializes Redux and React Router. */

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { AuthRoute } from './common/AuthRoute';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './common/history';

const renderRouteConfig = (routes, contextPath) => {
  // Resolve route config object in React Router v3.
  const children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfig(item.childRoutes, newContextPath);
      if (item.auth === true) {
        children.push(
          <AuthRoute
            key={newContextPath}
            pathRender={props => <item.component {...props}>{childRoutes}</item.component>}
            path={newContextPath}
          />,
        );
      } else {
        children.push(
          <Route
            key={newContextPath}
            render={props => <item.component {...props}>{childRoutes}</item.component>}
            path={newContextPath}
          />,
        );
      }
    } else if (item.component) {
      if (item.auth === true) {
        children.push(
          <AuthRoute key={newContextPath} component={item.component} path={newContextPath} exact />,
        );
      } else {
        children.push(
          <Route key={newContextPath} component={item.component} path={newContextPath} exact />,
        );
      }
    } else if (item.childRoutes) {
      item.childRoutes.forEach(r => renderRoute(r, newContextPath));
    }
  };

  routes.forEach(item => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
};

const Root = ({ store, routeConfig }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{renderRouteConfig(routeConfig, '/')}</ConnectedRouter>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
  routeConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Root;
