import {
  CRA_REPORTS_DETAILS_BEGIN,
  CRA_REPORTS_DETAILS_SUCCESS,
  CRA_REPORTS_DETAILS_FAILURE,
  CRA_REPORTS_DETAILS_DISMISS_ERROR,
  CRA_REPORTS_DETAILS_UPDATE_COMPLETE_REPORT,
  SET_CRA_REPORT_DETAILS_RENDERED_REPORT_COMPONENTS,
} from './constants';
import { REACT_APP_API_KEY } from '../../../aws-exports';

import { API } from 'aws-amplify';
import { checkUser, updateAuthToken, activityLogCRAPayload } from '../../home/redux/actions';
import { initialState } from './initialState';

// Rekit uses redux-thunk for async actions by default: https://github.com/gaearon/redux-thunk
// If you prefer redux-saga, you can use rekit-plugin-redux-saga: https://github.com/supnate/rekit-plugin-redux-saga
export const queryCRAReportDetails = ({ reportID }) => {
  return (dispatch, getState) => {
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    return new Promise((resolve, reject) => {
      (async () => {
        const { email, jwt } = await getState().home;
        try {
          if (!email) {
            reject('No authenticated user');
            return;
          }
          dispatch({
            type: CRA_REPORTS_DETAILS_BEGIN,
          });
          const apiName = 'crareportapi';
          const path = '/cra/getreportdetails';
          const myInit = {
            // OPTIONAL
            headers: {
              Authorization: jwt,
              'x-api-key': REACT_APP_API_KEY,
            }, // OPTIONAL
            //  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {
              // OPTIONAL
              reportID: reportID,
            },
          };
          const response = await API.get(apiName, path, myInit);
          const activity = 'Read';
          await dispatch(activityLogCRAPayload({ reportID, activity }));
          await dispatch({
            type: CRA_REPORTS_DETAILS_SUCCESS,
            data: response,
          });
          const { reportitems } = await getState();
          resolve(reportitems);
        } catch (error) {
          if (error?.response?.status === 401) {
            await dispatch(updateAuthToken());
            await dispatch(queryCRAReportDetails({ reportID }));
          } else {
            await dispatch({
              type: CRA_REPORTS_DETAILS_FAILURE,
              data: { error: error },
            });
            reject(error);
          }
        }
      })();
    });
  };
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissQueryReportDetailsError = () => {
  return {
    type: CRA_REPORTS_DETAILS_DISMISS_ERROR,
  };
};

export const maskImage = ({ reportID, payload = {} }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const { jwt } = await getState().home;
        try {
          const apiName = 'crareportapi';
          const path = '/cra/maskimage';
          const options = {
            // OPTIONAL
            headers: {
              Authorization: jwt,
              'x-api-key': REACT_APP_API_KEY,
            },
            queryStringParameters: {
              reportID: reportID,
            },
            body: payload,
          };
          const response = await API.post(apiName, path, options);
          resolve(response);
        } catch (error) {
          if (error?.response?.status === 401) {
            await dispatch(updateAuthToken());
            await dispatch(maskImage({ reportID, payload }));
          } else {
            reject(error);
          }
        }
      })();
    });
  };
};

export const get = ({ path, queryStringParameters, ...others }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { jwt } = await getState().home;
          const apiName = 'crareportapi';
          let options = {
            // OPTIONAL
            headers: {
              Authorization: jwt,
              'x-api-key': REACT_APP_API_KEY,
            },
            // response: true,
            queryStringParameters: {
              ...queryStringParameters,
            },
          };
          const response = await API.get(apiName, path, options);
          resolve(response);
        } catch (error) {
          if (error?.response?.status === 401) {
            await dispatch(updateAuthToken());
            await dispatch(get({ path, queryStringParameters, ...others }));
          } else {
            reject(error);
          }
        }
      })();
    });
  };
};

export const post = ({ path, queryStringParameters, body, ...others }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { jwt } = await getState().home;
          const apiName = 'crareportapi';
          const options = {
            headers: {
              Authorization: jwt,
              'x-api-key': REACT_APP_API_KEY,
            },
            body: body,
          };
          if (queryStringParameters) {
            options.queryStringParameters = queryStringParameters;
          }
          const response = await API.post(apiName, path, options);
          resolve(response);
        } catch (error) {
          if (error?.response?.status === 401) {
            await dispatch(updateAuthToken());
            await dispatch(post({ path, queryStringParameters, body, ...others }));
          } else {
            reject(error);
          }
        }
      })();
    });
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case CRA_REPORTS_DETAILS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        reportDetails: null,
        loading: true,
        queryCRAReportDetailsPending: true,
        queryCRAReportDetailsError: null,
        reportActionResult: null,
        completeReport: {},
        renderedReportItems: [],
      };

    case CRA_REPORTS_DETAILS_SUCCESS:
      // The request is success
      return {
        ...state,
        reportDetails: action.data,
        loading: false,
        queryCRAReportDetailsPending: false,
        queryCRAReportDetailsError: null,
      };

    case CRA_REPORTS_DETAILS_FAILURE:
      // The request is failed
      return {
        ...state,
        loading: false,
        queryCRAReportDetailsPending: false,
        queryCRAReportDetailsError: action.data.error,
        completeReport: {},
        renderedReportItems: [],
      };

    case CRA_REPORTS_DETAILS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loading: false,
        queryCRAReportDetailsError: null,
      };

    case CRA_REPORTS_DETAILS_UPDATE_COMPLETE_REPORT: {
      return {
        ...state,
        completeReport: action.data,
      };
    }
    case SET_CRA_REPORT_DETAILS_RENDERED_REPORT_COMPONENTS: {
      return {
        ...state,
        renderedReportItems: action.data,
      };
    }
    case 'CLEAR_CRA_REPORT_DETAILS': {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
