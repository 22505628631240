import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingView } from '../common';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { CSVLink } from "react-csv";
import { queryCRAReports } from './redux/actions';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './style.scss';

const getStoredDateRange = () => {
  const storeddateRange = JSON.parse(localStorage.getItem('dateRange'));
  if (storeddateRange) {
    return storeddateRange;
  }
  const d = new Date();
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();

  const startHour = Date.UTC(year, month, day, 0, 0, 0, 0);
  const endHour = startHour + 86400000;
  return [new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), new Date(endHour)];
};

const SearchComponent = props => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className="search-component">
        <input
          value={props.searchText}
          className="search-component-input"
          type="text"
          onChange={e => props.onSearch(e.target.value)}
        />
        <button type="submit" className="fa fa-search search-component-icon" />
      </div>
      {props.children}
    </div>
  );
};

export const TabularReport = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const report = useSelector(state => state.report);

  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [dateRange, setDateRange] = useState(getStoredDateRange());
  const [dateRangeChanged, setDateRangeChanged] = useState(null);
  const [dateRangeError, setDateRangeError] = useState(false);
  const [exportCSVData, setExportCSVData] = useState([]);
  const excelRef = useRef()

  const updateWindowDimensions = () => {
    setWindowDimensions(prev => ({
      ...prev,
      width: window.innerWidth,
      height: window.innerHeight,
    }));
  };

  const onDateRangeChange = dateRange => {
    if (dateRange) {
      setDateRange(dateRange);
      const [startDate, endDate] = dateRange;
      if (startDate < endDate) {
        setDateRangeChanged(true);
        setDateRangeError(false);
      } else {
        window.alert('Start Date is greater than End Date in Filters');
        setDateRangeError(true);
      }
    } else {
      setDateRange(null);
      setDateRangeError(false);
    }
  };

  const gotoDetailsScreen = (e, row, i) => {
    history.push({
      pathname: '/report-details',
      state: { reportID: row.reportID, reportActionResult: null },
    });
  };

  const dateFormatter = (cell, row) => {
    const date = new Date(cell);
    //const formattedDate = format(date, "MMMM Do, YYYY H:mma")
    return <span>{date.toString()} </span>;
  };

  const { SearchBar } = Search;

  const selectOptions = {
    Read: 'Read',
    New: 'New',
    Investigating: 'Investigating',
    'Determination Complete': 'Determination Complete',
    Duplicate: 'Duplicate',
    'Test Record': 'Test Record',
  };

  const selectBusinessLineOptions = {
    Boost: 'Boost',
    DBS: 'DBS',
    Wireless: 'Wireless',
    Others: 'Others',
  };

  const selectRegionOptions = {
    Midwest: 'Midwest',
    Northeast: 'Northeast',
    'South Central': 'South Central',
    Southeast: 'Southeast',
    West: 'West',
    Other: 'Other',
  };

  const selectReportOptions = {
    true: 'Completed',
    false: 'Partial',
  };

  const { reportlist, calendar, startDate, endDate, selectedRow, loading } = report;

  let items = reportlist;
  if (!items) {
    items = [];
  }
  for (let i = 0; i < items.length; ++i) {
    let obo = items[i]['personalInfo']['onBehalfOf'];
    obo = obo === 'Myself' ? 'Self' : obo;
    items[i]['personalInfo']['onBehalfOf'] = obo;

    let isPartial = items[i]['isReportComplete'] ? true : false;
    items[i]['isReportComplete'] = isPartial;
  }

  let columns = [
    {
      dataField: 'personalInfo.techName',
      text: 'Submitted By',
      /*filter: textFilter()*/
    },
    {
      dataField: 'personalInfo.onBehalfOf',
      text: 'On Behalf Of',
      searchable: true,
    },
    {
      dataField: 'PayloadStatus',
      text: 'Claim Status',
      formatter: (cellContent, row) => selectOptions[cellContent],
      filter: selectFilter({
        options: selectOptions,
        style: {
          cursor: 'pointer',
        },
      }),
    },
    {
      dataField: 'isReportComplete',
      text: 'Report Submitted?',
      formatter: (cellContent, row) => (cellContent ? 'Completed' : 'Partial'),
      filter: selectFilter({
        options: selectReportOptions,
        style: {
          cursor: 'pointer',
        },
      }),
    },
    {
      dataField: 'personalInfo.businessLine',
      text: 'Business Line',
      formatter: (cellContent, row) => selectBusinessLineOptions[cellContent],
      filter: selectFilter({
        options: selectBusinessLineOptions,
        style: {
          cursor: 'pointer',
        },
      }),
    },
    {
      dataField: 'personalInfo.techRegionName',
      text: 'Region',
      formatter: (cellContent, row) => selectRegionOptions[cellContent],
      filter: selectFilter({
        options: selectRegionOptions,
        style: {
          cursor: 'pointer',
        },
      }),
    },
    {
      dataField: 'personalInfo.techOfficeName',
      text: 'Office',
      searchable: true,
    },
    {
      dataField: 'InsertTime',
      text: 'Reported Date',
      formatter: dateFormatter,
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return <span class="order-4"></span>;
        else if (order === 'asc') return <span class="caret-4-asc"></span>;
        else if (order === 'desc') return <span class="caret-4-desc"></span>;
        return null;
      },
      searchable: false,
    },
    {
      dataField: 'personalInfo.accidentDate',
      text: 'Accident Date',
      formatter: dateFormatter,
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return <span class="order-4"></span>;
        else if (order === 'asc') return <span class="caret-4-asc"></span>;
        else if (order === 'desc') return <span class="caret-4-desc"></span>;
        return null;
      },
      searchable: false,
    },
    {
      dataField: 'reportID',
      text: 'Report Number',
      searchable: true,
    },
    // {
    //   dataField: 'AccidentDate',
    //   text: 'AccidentDate',
    //   searchable: false,
    // },
    // {
    //   dataField: 'id',
    //   text: 'View Report',
    //   editable: false,
    //   formatter: (cellContent, row) => {
    //     return <i className="fa fa-eye" aria-hidden="true"></i>;
    //   },
    // },
    // {
    //   dataField: 'personalInfo.techID',
    //   text: 'Employee Id',
    //   filter: textFilter()
    // },
  ];

  const options = {
    //paginationSize: 0,
    hideSizePerPage: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    prePageText: '<',
    nextPageText: '>',
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '20',
        value: 20,
      },
      {
        text: '25',
        value: 25,
      },
    ],
    sizePerPage: 20,
    pageStartIndex: 1,
    page: 1,
    //paginationSize: 0
  };

  // Timer logic to trigger the Query Reports API
  // Now the API is triggered only after 2 seconds of last input
  const timeouts = [];
  const originalTimeoutFn = window.setTimeout;
  window.setTimeout = (fun, delay) => {
    const t = originalTimeoutFn(fun, delay);
    timeouts.push(t);
  };

  const clearTimeouts = () => {
    while (timeouts.length) {
      clearTimeout(timeouts.pop());
    }
  };

  const CSVHeaders = [
    { label: "Submitted By", key: "technicianName" },
    { label: "On Behalf Of", key: "onBehalfOf" },
    { label: "Claim Status", key: "claimstatus" },
    { label: "Report Submitted", key: "reportSubmitted" },
    { label: "Business Line", key: "businessLine" },
    { label: "Region", key: "region" },
    { label: "Office", key: "office" },
    { label: "Reported Date", key: "reportedDate" },
    { label: "Accident Date", key: "accidentDate" },
    { label: "Report Number", key: "reportNumber" },
    { label: "Submitted Tech ID", key: "submittedTechId" },
    { label: "Manager Name", key: "managerName" },
    { label: "Unit Number", key: "unitNumber" },
    { label: "Report URL", key: "reportUrl" },
  ];


  const exportCSV = () => {
    console.log(items)
    const exportData = items.map(item=> {
      return {
        "technicianName" : item.personalInfo.techName,
        "claimstatus" : item.PayloadStatus,
        "reportSubmitted" : item.isReportComplete === true ? "Completed": "Partial",
        "managerName" : item.personalInfo.managerName,
        "unitNumber" : item.personalInfo.techUnitNumber,
        "businessLine" : item.personalInfo.businessLine,
        "region" : item.personalInfo.techRegionName,
        "office" : item.personalInfo.techOfficeName,
        "reportedDate" : new Date(item.InsertTime).toString(),
        "accidentDate" : item.personalInfo.accidentDate,
        "onBehalfOf" : item.personalInfo.onBehalfOf,
        "reportNumber" : item.reportID,
        "submittedTechId" : item.SubmittedTechId,
        "reportUrl" : `${process.env.NODE_ENV === 'development' ? 'https://cra-dashboard-np.dish.com?reportID=': 'https://cra-dashboard.dish.com?reportID='}${item.reportID}`,
      }
    })
    
    setExportCSVData([...exportData]);
  }

  useEffect(()=> {
    if (exportCSVData.length > 0) {
      excelRef.current.link.click()
    }
  },[exportCSVData])

  useEffect(() => {
    if (dateRangeChanged === true) {
      clearTimeouts();
      setTimeout(() => {
        setDateRangeChanged(false);
      }, 2000);
    }
    if (dateRangeChanged === false && dateRangeChanged !== null && dateRange) {
      dispatch(queryCRAReports({ dateRange }));
      setDateRangeChanged(null);
    }
  }, [dateRangeChanged]);

  useEffect(() => {
    dispatch(queryCRAReports({ dateRange }));
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  if (report.loading || report.queryCRAReportsPending) return <LoadingView />;

  return (
    <ToolkitProvider keyField="reportID" data={items} columns={columns} search>
      {props => (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'ROW',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'end',
              paddingTop: 10,
            }}>
            <div className="report-left-chart">
              <h3 className="title-content title-text" style={{ color: 'black', margin: 0 }}></h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h3 className="filter-text">Filter By:</h3>
              <DateRangePicker
                className={dateRangeError ? 'date-range-error' : ''}
                onChange={onDateRangeChange}
                value={dateRange}
                showLeadingZeros={true}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <SearchComponent {...props.searchProps}>
                  <div>
                    <button
                      type="submit"
                      className="fa fa-refresh search-refresh-icon"
                      href="/report"
                      onClick={() => {
                        dispatch(queryCRAReports({ dateRange }));
                      }}
                    />
                  </div>
                </SearchComponent>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button
                className="btn btn-danger"
                style={{  marginBottom: 0 }}
                onClick={() => exportCSV()}>
                Download as CSV
              </Button>

              <CSVLink
                data={exportCSVData}
                headers={CSVHeaders}
                filename={"CRA_Report.csv"}
                ref={excelRef}
                className='hiddenExcel'
                target='_blank'
              ></CSVLink>
            </div>
          </div>
          {items.length < 1 ? (
            <div
              style={{
                width: '100%',
                height: 500,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 18,
              }}>
              <span>No Items to display. Reload the page or modify the filter/search</span>
            </div>
          ) : (
            <div className="table-container">
              <BootstrapTable
                condensed
                striped
                hover
                {...props.baseProps}
                keyField="reportID"
                data={items}
                columns={columns}
                bordered={false}
                rowEvents={{ onClick: gotoDetailsScreen, style: { cursor: 'pointer' } }}
                pagination={paginationFactory(options)}
                filter={filterFactory()}
                bootstrap5
              />
            </div>
          )}
        </div>
      )}
    </ToolkitProvider>
  );
};
