const Region = {
  SELECT: 'Select',
  MIDWEST: 'Midwest',
  NORTH_EAST: 'Northeast',
  SOUTH_CENTRAL: 'South Central',
  SOUTHEAST: 'Southeast',
  WEST: 'West',
};

const BusinessLine = {
  ALL: 'All',
  BOOST: 'Boost',
  DBS: 'DBS',
  WIRELESS: 'Wireless',
};

export { Region, BusinessLine };
