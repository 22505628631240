import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

export const PinEntryPopUp = props => {
  const { setPIN, pinError } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPINError, setIsPINError] = useState(false);
  const [userPIN, setUserPIN] = useState('');
  const [savedPIN, setSavedPIN] = useState(window.atob(localStorage.getItem('pin')));

  const handleSubmit = () => {
    setPIN(userPIN);
    setUserPIN('');
    props.onSubmit();
  };

  const onHide = evt => {
    setUserPIN('');
    setIsDisabled(true);
    setIsPINError(false);
  };

  useEffect(() => {
    if (userPIN.length === 4) {
      if (userPIN === savedPIN) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
        setIsPINError(true);
      }
    } else {
      setIsDisabled(true);
      setIsPINError(false);
    }
  }, [userPIN]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      animation={false}>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter">Enter Pin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            type="password"
            maxLength="4"
            autoFocus
            value={userPIN}
            style={{
              borderColor: '#000000',
              borderWidth: 0.5,
              borderRadius: 5,
              marginLeft: '20px',
              fontSize: '27px',
              fontWeight: 'bold',
              textAlign: 'center',
              maxWidth: '150px',
            }}
            onChange={pin => {
              let userEntry = pin.target.value;
              setUserPIN(userEntry);
            }}
            onKeyPress={e => {
              if (!isDisabled && !isPINError && e.key === 'Enter') handleSubmit();
            }}
          />
          <div style={{ marginLeft: '4.5%', marginTop: 5, color: 'red', fontSize: '12px' }}>
            {isPINError ? 'Incorrect Pin' : ''}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" disabled={isDisabled} onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
