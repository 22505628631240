import React, { useEffect } from 'react';
import { colList, formatBytes } from './Constant';
import awsExports, { REACT_APP_API_KEY } from '../../aws-exports';
import { Amplify, Auth, Storage } from 'aws-amplify';
import moment from 'moment';
import { FileType } from './FileTypes';
import axios from 'axios';

Amplify.configure(awsExports);

export const FilePicker = props => {
  const [files, setFilesList] = React.useState([]);
  const [uploadedFilesList, setUploadedFilesList] = React.useState([]);
  const inputOpenFileRef = React.createRef();

  useEffect(() => {
    let json_key_name = 'extradocument_' + props.screenName;
    let reportsData = props.reportDetails[json_key_name];
    if (reportsData != null && reportsData != undefined) {
      let fileList = reportsData.map(data => {
        return {
          name: data.fileName,
          type: data.fileType,
          size: data.fileSize,
          timeOfUpload: data.timeOfUpload,
          uploaderName: data.uploaderName,
          fileUploadStatus: data.fileUploadStatus,
        };
      });
      setUploadedFilesList([...fileList]);
    }
  }, []);

  useEffect(() => {
    let reportsData = props.reportDetails[`extradocument_${props.screenName}`];
    if (reportsData != null && reportsData != undefined) {
      let fileList = reportsData.map(data => {
        var datetimeString = moment(data.timeOfUpload).format('MM/DD/YYYY, HH:MM ');
        return {
          name: data.fileName,
          documentPath: data.documentPath,
          type: data.fileType,
          size: data.fileSize,
          timeOfUpload: datetimeString,
          uploaderName: data.uploaderName,
          fileUploadStatus: data.fileUploadStatus,
        };
      });
      setUploadedFilesList([...fileList]);
    }
  }, [props.reportDetails]);

  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };

  const onChangeFile = event => {
    event.stopPropagation();
    event.preventDefault();
    var newFile = event.target.files[0];
    const editedFile = {
      name: newFile.name,
      type: newFile.type,
      size: formatBytes(newFile.size),
      file: newFile,
      fileUploadStatus: false,
    };
    setFilesList(files.concat(editedFile));
  };
  const deleteFile = file => {
    let filteredArray = files.filter(item => item !== file);
    setFilesList(filteredArray);
  };
  const onInputClick = event => {
    event.target.value = '';
  };

  const upLoadData = (reportID, file) => {
    return new Promise((resolve, reject) => {
      Auth.currentUserCredentials().then(auth => {
        Storage.put(
          `extradocuments/${reportID}/${props.screenName ? props.screenName : 'common'}/${
            file.name
          }`,
          file.file,
          {
            level: 'public',
            contentType: file.type,
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
          },
        )
          .then(result => {
            const custResponse = { key: result.key };
            resolve({
              response: custResponse,
              name: file.name,
              type: file.type,
              size: file.size,
            });
          })
          .catch(err => {
            console.log(err);
            reject({ response: err });
          });
      });
    });
  };

  const handleOnUpload = () => {
    let reportDetails = props.reportDetails;
    Storage.configure({
      AWSS3: {
        bucket: awsExports.Storage.AWSS3.bucket,
        region: awsExports.Storage.AWSS3.region,
        identityPoolId: awsExports.Storage.AWSS3.identityPoolId,
      },
    });
    try {
      let uploaderName = localStorage.getItem('LoginUsername');
      console.log('Username:', uploaderName);
      let url = `${awsExports.API.endpoints[0].endpoint}/cra/updatefileuploaddetails?reportID=${reportDetails.reportID}`;
      let promiseList = [];
      files.map(data => {
        data.fileUploadStatus == false &&
          promiseList.push(upLoadData(reportDetails.reportID, data));
      });
      props.isLoading(true);
      Promise.all(promiseList)
        .then(async pathList => {
          if (pathList.length > 0) {
            let params = pathList.map(data => {
              return {
                screenName: props.screenName ? props.screenName : 'incidentlocation',
                documentPath: data.response.key,
                uploaderName: uploaderName,
                timeOfUpload: moment().valueOf(),
                fileName: data.name,
                fileType: data.type,
                fileSize: data.size,
                fileUploadStatus: true,
              };
            });
            Auth.currentSession().then(sessionData => {
              const accessToken = sessionData.accessToken.jwtToken;
              const headers = new Headers({
                'Content-Type': 'application/json',
                Authorization: accessToken,
                'x-api-key': REACT_APP_API_KEY,
              });
              fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(params),
              })
                .then(response => response.text())
                .then(res => {
                  console.log('Result Success...:::', res);
                  setFilesList([]);
                  props.updateReportDetails(JSON.parse(res));
                  props.isLoading(false);
                });
            });
          } else {
            props.isLoading(false);
            alert('Files are already uploaded!.');
          }
        })
        .catch(error => {
          props.isLoading(false);
          console.log('errorPromise ==> ', error);
        });
    } catch (error) {
      props.isLoading(false);
      console.log('Error;::', error);
    }
  };

  const handleDownload = async file => {
    Storage.configure({ level: 'public', download: true, cacheControl: 'no-cache' });
    Storage.get(file.documentPath)
      .then(async result => {
        axios({
          url: result,
          method: 'GET',
          responseType: 'blob',
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => alert('Invalid file path. Try again!'));
      })
      .catch(err => console.log(err));
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <FileType
        title={'Additional Documents'}
        files={files}
        isFreshUpload={true}
        showOpenFileDlg={showOpenFileDlg}
        inputOpenFileRef={inputOpenFileRef}
        onInputClick={event => onInputClick(event)}
        onChangeFile={event => onChangeFile(event)}
        deleteFile={file => deleteFile(file)}
        handleOnUpload={handleOnUpload}
        isDisabled={props.isDisabled}
      />

      {uploadedFilesList.length > 0 && (
        <FileType
          title={'Uploaded Files'}
          files={uploadedFilesList}
          isFreshUpload={false}
          showOpenFileDlg={showOpenFileDlg}
          inputOpenFileRef={inputOpenFileRef}
          onInputClick={event => onInputClick(event)}
          onChangeFile={event => onChangeFile(event)}
          deleteFile={file => deleteFile(file)}
          handleOnUpload={handleOnUpload}
          downloadFile={handleDownload}
        />
      )}
    </div>
  );
};
