import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Auth } from 'aws-amplify';
import { TabContent, TabPane } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as actions from './redux/actions';
import {
  ActivityLog,
  CompanyVehicle,
  EmployeeInjuries,
  IncidentLocation,
  IncidentSceneReport,
  localJson,
  Police,
  ReportConstants,
  TechnicianInfo,
  ThirdPartyPedestrians,
  ThirdPartyProperty,
  ThirdPartyVechile,
  Witness,
  WorkFlowStatus,
} from './components';
import { LoadingView } from '../common';
import {
  CRA_REPORTS_DETAILS_UPDATE_COMPLETE_REPORT,
  SET_CRA_REPORT_DETAILS_RENDERED_REPORT_COMPONENTS,
} from './redux/constants';

const getReportRenderItems = ({
  reportItems,
  isExport = () => {},
  isLoading = () => {},
  updateReportDetails = () => {},
}) => {
  const {
    completeReport,
    reportActionResult: jsonDataResponse,
    reportDetails: {
      final_destination: ziplocation,
      extrafinal_destination: extrafinalDestination,
      Items: [reportDetails],
    },
  } = reportItems;
  const { PayloadStatus: reportStatus } = reportDetails;

  const reportRenderItemsArray = [];
  reportRenderItemsArray.push({
    name: 'Work Flow Status',
    component: <WorkFlowStatus completeJson={reportDetails} ziplocation={ziplocation} />,
  });
  reportRenderItemsArray.push({
    name: 'General Info',
    component: (
      <TechnicianInfo
        completeJson={reportDetails}
        ziplocation={ziplocation}
        isExport={isExport}
        extrafinal_destination={extrafinalDestination}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Incident Location',
    component: (
      <IncidentLocation
        jsondata={reportDetails.incidentLocation}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Third Party Vehicle',
    component: (
      <ThirdPartyVechile
        jsondata={reportDetails.vehicle}
        maskedImages={reportDetails.maskedImages}
        reportId={reportDetails.reportID}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Third Party Property',
    component: (
      <ThirdPartyProperty
        jsondata={reportDetails.property}
        maskedImages={reportDetails.maskedImages}
        reportId={reportDetails.reportID}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Third Party Pedestrians',
    component: (
      <ThirdPartyPedestrians
        jsondata={reportDetails.pedestrian}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Witness',
    component: (
      <Witness
        jsondata={reportDetails.witness}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Incident Scene/Report',
    component: (
      <IncidentSceneReport
        jsondata={reportDetails.incident}
        maskedImages={reportDetails.maskedImages}
        reportId={reportDetails.reportID}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Company Vehicle',
    component: (
      <CompanyVehicle
        jsondata={reportDetails.companyVehicle}
        maskedImages={reportDetails.maskedImages}
        reportId={reportDetails.reportID}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Police',
    component: (
      <Police
        jsondata={reportDetails.police}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Employee Injuries',
    component: (
      <EmployeeInjuries
        jsondata={reportDetails.employeeInjuries}
        reportDetails={Object.keys(completeReport).length > 0 ? completeReport : reportDetails}
        isLoading={isLoading}
        updateReportDetails={updateReportDetails}
        reportStatus={reportStatus}
        jsondataresponse={jsonDataResponse}
      />
    ),
  });
  reportRenderItemsArray.push({
    name: 'Notes',
    component: (
      <ActivityLog
        jsondata={reportDetails}
        jsondataresponse={jsonDataResponse}
        report={reportDetails}
        actions={actions}
        reportStatus={reportStatus}
      />
    ),
  });
  return reportRenderItemsArray;
};

const ReportDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { toggleSideMenu, queryCRAReportDetails } = actions;

  const reportitems = useSelector(state => state.reportitems);
  const sideMenu = useSelector(state => state.report.sideMenu);

  const [activeTab, setActiveTab] = useState('');
  const [reportRenderItems, setReportRenderItems] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isExport, setExport] = useState(false);
  const [currentReportID, setCurrentReportID] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [updatedReportData, setUpdatedReportData] = useState(null);
  const [noReportItems, setNoReportItems] = useState(false);

  const updateWindowDimensions = () =>
    setWindowDimensions(prev => ({
      ...prev,
      width: window.innerWidth,
      height: window.innerHeight,
    }));

  const signOut = () => {
    Auth.signOut({ global: false })
      .then(data => {
        dispatch(checkUser());
      })
      .catch(err => {
        dispatch(checkUser(true));
      });
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (sideMenu) {
        dispatch(toggleSideMenu());
      }
    }
  };

  const renderSideMenu = () => {
    return (
      <div className="bg-light border-right leftmenuwidth" id="sidebar-wrapper">
        <div className="list-group list-group-flush leftmenuwidth">
          {reportRenderItems.map((item, key) => (
            <a
              href="#"
              key={`sidemenu-${key}`}
              onClick={() => toggle(item.name)}
              className={
                'list-group-item list-group-item-action ' +
                (activeTab === item.name ? 'active' : 'bg-light')
              }>
              {item.name}
            </a>
          ))}
          <span className="display-small-screen">
            <a
              onClick={() => signOut()}
              className="list-group-item list-group-item-action bg-light">
              <strong>
                <i className="fa fa-power-off"></i>&nbsp; Sign Out
              </strong>
            </a>
          </span>
        </div>
        <div className="filter-container"></div>
      </div>
    );
  };

  const renderSideNavigationBar = () => {
    return (
      <React.Fragment>
        <div className="menuRootTitle leftmenuwidth">
          <a
            className="fa fa-arrow-left"
            href="#"
            onClick={() => {
              history.push({ pathname: '/report' });
            }}></a>
          <a
            className="menuRootText"
            href="#"
            onClick={() => {
              history.push({ pathname: '/report' });
            }}>
            {' '}
            Dashboard
          </a>
        </div>
        <div className="menuHeaderTitle leftmenuwidth">
          <p className="menuText">Collision Report</p>
        </div>
      </React.Fragment>
    );
  };

  const updateCompleteReport = data => {
    dispatch({
      type: CRA_REPORTS_DETAILS_UPDATE_COMPLETE_REPORT,
      data: data,
    });
    setUpdatedReportData(data);
  };

  const getReportItems = (items = reportitems) => {
    let reportItems = items;
    const { loading, reportDetails } = reportItems;
    if (loading) setLoading(true);

    if (ReportConstants.uselocalJOSN) {
      reportItems = { reportItems: { Items: [localJson] } };
    }
    if (reportDetails && reportDetails.Items && reportDetails.Items.length > 0) {
      const reportRenderItemsArray = getReportRenderItems({
        reportItems,
        isExport: stat => setExport(stat),
        isLoading: stat => setLoading(stat),
        updateReportDetails: data => updateCompleteReport(data),
      });
      dispatch({
        type: SET_CRA_REPORT_DETAILS_RENDERED_REPORT_COMPONENTS,
        data: reportRenderItemsArray,
      });
      setReportRenderItems(reportRenderItemsArray);
      if (windowDimensions.width > 0 && windowDimensions.width < 1032) {
        setReportRenderItems(reportRenderItemsArray.shift());
        if (activeTab === 'Work Flow Status') {
          toggle(reportRenderItemsArray[0].name);
        }
      }
      if (activeTab == '') {
        toggle(reportRenderItemsArray[0].name);
      }
    } else {
      setNoReportItems(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      if (!ReportConstants.uselocalJOSN && location.state && location.state.reportID) {
        const reportID = location.state.reportID;
        setCurrentReportID(reportID);
        dispatch(queryCRAReportDetails({ reportID })).then(res => {
          updateWindowDimensions();
          getReportItems(res);
          setLoading(false);
        });
      } else {
        history.push({ pathname: '/report' });
      }
    } catch (err) {
      history.push({ pathname: '/report' });
    }
    window.addEventListener('resize', () => updateWindowDimensions());
    return () => {
      window.removeEventListener('resize', () => updateWindowDimensions());
    };
  }, []);

  useEffect(() => {
    if (updatedReportData && currentReportID.trim().length > 0) getReportItems();
  }, [updatedReportData]);

  if (noReportItems) {
    return (
      <div>
        {sideMenu && (
          <React.Fragment>
            <div className="menuRootTitle leftmenuwidth">
              <a className="fa fa-arrow-left" href="/report"></a>
              <a className="menuRootText" href="/report">
                {' '}
                Dashboard
              </a>
            </div>
            <div className="menuRootTitle leftmenuwidth">
              <span className="display-small-screen">
                <a onClick={() => signOut()} className="menuRootText">
                  <i className="fa fa-power-off"></i> &nbsp; Sign Out
                </a>
              </span>
            </div>
            <center>
              <h1>Item not found</h1>
            </center>
          </React.Fragment>
        )}
      </div>
    );
  }

  if (isLoading) {
    return <LoadingView backdrop={true} />;
  }

  return (
    <div id={`report-details`}>
      {sideMenu
        ? windowDimensions.width < 1024 && renderSideNavigationBar()
        : windowDimensions.width > 1024 && renderSideNavigationBar()}
      <div>
        {isExport && <LoadingView backdrop />}
        <div className="d-flex" id="wrapper">
          {sideMenu
            ? windowDimensions.width < 1024 && renderSideMenu()
            : windowDimensions.width > 1024 && renderSideMenu()}
          {/* {sideMenu &&

            <div className="bg-light border-right leftmenuwidth" id="sidebar-wrapper">

              <div className="list-group list-group-flush leftmenuwidth">
                {
                  this.state.reportRenderItems.map((item, key) =>
                    <a href="#" onClick={() => this.toggle(item.name)} className={"list-group-item list-group-item-action " + (this.state.activeTab === item.name ? "active" : "bg-light")}>{item.name}</a>
                  )
                }
                <span className='display-small-screen'>
                  <a onClick={this.signOut} className="list-group-item list-group-item-action bg-light"><strong><i className="fa fa-power-off"></i>&nbsp; Sign Out</strong></a>

                </span>
              </div>
              <div className="filter-container">
              </div>
            </div>} */}
          <TabContent className="submenuBody" activeTab={activeTab}>
            {reportRenderItems.map((item, key) => (
              <TabPane key={`tabpane-${key}`} tabId={item.name}>
                {/* {!sideMenu &&
                    <div className="space"></div>} */}
                {item.component}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
      <div style={{ display: isExport ? 'block' : 'none' }}>
        {reportRenderItems.map((item, index) => {
          return (
            <div key={`div-iem-${index}`} id={'item' + index}>
              {item.component}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportDetails;
