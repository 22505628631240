import { combineReducers } from 'redux';
import { history } from './history';
import { connectRouter } from 'connected-react-router';
import { homeReducer } from '../features/home/redux/reducer';
import { commonReducer } from '../features/common/redux/reducer';
import { loginReducer } from '../features/login/redux/reducer';
import { reportReducer } from '../features/report/redux/reducer';
import { reportItemsReducer } from '../features/reportitems/redux/reducer';

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  login: loginReducer,
  report: reportReducer,
  reportitems: reportItemsReducer,
};

export const rootReducer = combineReducers(reducerMap);
