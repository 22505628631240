import React, { Fragment, useState, useEffect } from 'react';
import { ReportConstants } from './ReportConstants';
import { Row, Col } from 'reactstrap';
import { FilePicker } from '../../../common/FileUpload';

export const ThirdPartyPedestrians = props => {
  const [isDisable, setIsDisable] = useState(false);
  useEffect(() => {
    let activitylog = null;
    if (props.jsondataresponse) {
      activitylog = props.jsondataresponse.Attributes.activitylog;
    }
    if (activitylog != null && activitylog.length > 0) {
      setIsDisable(activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected');
    }
  }, [props]);
  const inputJson = props.jsondata;
  let count = 0;
  if (inputJson !== null && inputJson != 'undefined' && typeof inputJson === 'object') {
    count = Object.keys(inputJson).length;
  }

  return (
    <Fragment>
      <div className="report_main_section small-screen-text-center">
        <span className="p4-black">Third Party Pedestrians ({count})</span>
        <br />
        <div>
          {inputJson ? (
            inputJson.map((item, key) => (
              <div>
                <Row>
                  <Col>
                    <p className="p4-black-sub-header">Pedestrian Info - {key + 1}</p>
                    <table className="table-details">
                      <tr>
                        <td className="p4-black-light">Name</td>
                        <td className="p4-black-light">{item.name ? item.name : '---'}</td>
                      </tr>
                      <tr>
                        <td className="p4-black-light">Phone</td>
                        <td className="p4-black-light">{item.phone ? item.phone : '---'}</td>
                      </tr>
                    </table>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <div>
              <span className="h5-heading">Third party pedestrians details not found</span>
            </div>
          )}
        </div>
        {ReportConstants.displayInputJson ? (
          <div>
            <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>
          </div>
        ) : (
          ''
        )}
      </div>
      <Col className="mobileUpload" style={{ marginTop: '1%' }}>
        <FilePicker
          screenName={'pedestrian'}
          reportDetails={props.reportDetails}
          isLoading={stat => props.isLoading(stat)}
          updateReportDetails={props.updateReportDetails}
          isDisabled={
            props.reportStatus === 'Closed' || props.reportStatus === 'Rejected' || isDisable
          }
        />
      </Col>
    </Fragment>
  );
};
