import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { ModalDropDown } from '.';
import { LoadingView } from '../common';
import { getBusinessLine, postBusinessLine } from '../../fetchBusinessLine';

export const DefaultPage = () => {
  const history = useHistory();
  const home = useSelector(state => state.home);

  const [doaction, setDoaction] = useState(true);
  const [loading, setLoading] = useState(false);
  const [getBusinessLineStatusCode, setGetBusinessLineStatusCode] = useState({});
  const [postBusinessLineRes, setPostBusinessLineRes] = useState('');

  const moveToReports = () => {
    if (Object.keys(getBusinessLineStatusCode).length === 0 && home?.username && doaction) {
      setLoading(true);
      getBusinessLine()
        .then(res => setGetBusinessLineStatusCode(res))
        .catch(err => {
          return;
        })
        .finally(() => setLoading(false));
    }

    if (home.username && doaction) {
      setTimeout(() => {
        const reportDetails = JSON.parse(localStorage.getItem('reportDetails'));
        let callReport = true;
        if (reportDetails) {
          if (reportDetails.time > Date.now() - 1 * 60 * 60 * 1000) {
            history.push({
              pathname: '/report-details',
              state: { reportID: reportDetails.reportID, reportActionResult: null },
            });
            callReport = false;
          }
        }
        localStorage.setItem('reportDetails', null);

        if (callReport) {
          history.push('/report');
        }
      }, 1000);
      setDoaction(false);
    }
  };

  const businessLine = value => {
    setLoading(true);
    postBusinessLine(value)
      .then(res => setPostBusinessLineRes(res))
      .catch(err => setPostBusinessLineRes(''))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (home.checked && !home.checkUserPending) {
      moveToReports();
    }
  }, [home]);

  if (loading || !home.checked || home.checkUserPending) {
    return <LoadingView />;
  }

  if (
    getBusinessLineStatusCode != '' &&
    getBusinessLineStatusCode?.code === 500 &&
    loading != true &&
    postBusinessLineRes === ''
  ) {
    return <ModalDropDown businessValue={value => businessLine(value)} />;
  }

  return (
    <div className="home-default-page">
      <div className="app-intro">
        <div>
          <h3 style={{marginTop: '16px'}}>You are not logged in, please click here to login</h3>
          <ul>
            <li>
              <Button
                color="danger"
                outline
                size="lg"
                onClick={() => {
                  history.push('/login');
                }}
                target="_blank"
                rel="noopener noreferrer">
                Login
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
