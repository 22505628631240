import {
  HOME_CHECK_USER_BEGIN,
  HOME_CHECK_USER_SUCCESS,
  HOME_CHECK_USER_FAILURE,
  HOME_CHECK_USER_DISMISS_ERROR,
  UPDATE_USER_JWT,
} from './constants';
import { Auth } from 'aws-amplify';

export const checkUser = shouldFail => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { home } = await getState();
          if (!home.username && !home.checkUserPending) {
            await dispatch({
              type: HOME_CHECK_USER_BEGIN,
            });
          }
          if (!home.checkUserPending) {
            const response = await Auth.currentAuthenticatedUser({ bypassCache: true });
            const emailID = response.username.substring(response.username.indexOf('_') + 1);
            const username = emailID.substring(0, emailID.indexOf('@'));
            const jwtToken = response.signInUserSession.accessToken.jwtToken;
            localStorage.setItem('LoginUsername', username);
            await dispatch({
              type: HOME_CHECK_USER_SUCCESS,
              data: {
                username: response.username,
                email: emailID,
                jwt: jwtToken,
                error: null,
              },
            });
            resolve(response);
          }
        } catch (error) {
          await dispatch({
            type: HOME_CHECK_USER_FAILURE,
            data: {
              username: null,
              email: null,
              jwt: null,
              error: error,
            },
          });
          reject(error);
        }
      })();
    });
  };
};

export const dismissCheckUserError = () => {
  return {
    type: HOME_CHECK_USER_DISMISS_ERROR,
  };
};

export const signOut = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      Auth.signOut({ global: false })
        .then(data => {
          dispatch(checkUser());
          resolve(data);
        })
        .catch(err => {
          dispatch(checkUser(true));
          reject(err);
        });
    });
  };
};

export const updateAuthToken = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const session = await Auth.currentSession();
          const jwtToken = session.getAccessToken().getJwtToken();
          await dispatch({
            type: 'UPDATE_USER_JWT',
            data: {
              jwt: jwtToken,
              checkUserPending: false,
              checkUserError: null,
              checked: true,
            },
          });
          resolve(jwtToken);
        } catch (error) {
          reject(error);
        }
      })();
    });
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case HOME_CHECK_USER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        checkUserPending: true,
        checkUserError: null,
        email: null,
        username: null,
        jwt: null,
        checked: false,
      };

    case HOME_CHECK_USER_SUCCESS:
      // The request is success
      return {
        ...state,
        ...action.data,
        checkUserPending: false,
        checkUserError: null,
        checked: true,
      };

    case HOME_CHECK_USER_FAILURE:
      // The request is failed
      return {
        ...state,
        ...action.data,
        checkUserPending: false,
        username: null,
        checked: true,
      };

    case HOME_CHECK_USER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        checkUserError: null,
        email: null,
        username: null,
        jwt: null,
        checked: true,
      };

    case UPDATE_USER_JWT:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};
