import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { DropDown } from '.';

export const ModalDropDown = props => {
  const [show, setShow] = useState(true);
  const [business, setBusiness] = useState('All');
  const [region, setRegion] = useState('Select');

  const handleClose = () => {
    setShow(false);
    props.businessValue({ business: business, region: region });
    localStorage.setItem('userBusiness', business);
    localStorage.setItem('userRegion', region);
  };
  const handleShow = () => setShow(true);
  const onBusinessChange = value => setBusiness(value);
  const setRegionChange = value => setRegion(value);

  return (
    <Modal show={show} onHide={handleClose} animation={false} backdrop={false}>
      <Modal.Header>
        <Modal.Title>Select Business Line</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DropDown
          onBusiness={value => onBusinessChange(value)}
          onRegion={value => setRegionChange(value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="btn btn-danger">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
