import React, { useEffect, useState } from 'react';
import { ReportConstants } from './ReportConstants';
import { Button } from 'reactstrap';
import { activityLogCRAPayload } from '../redux/actions';
import { Row, Col } from 'reactstrap';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { PinEntryPopUp } from '../../common';
import { useDispatch, useSelector } from 'react-redux';

export const ActivityLog = props => {
  const dispatch = useDispatch();

  const activityLogData = useSelector(
    state => state?.reportitems?.reportActionResult?.Attributes?.activitylog ?? null,
  );

  const [comment, setComment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState('');
  const [incorrectPIN, setIncorrectPIN] = useState(false);
  const [previousStatus, setPreviousStatus] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [isDisabled, setIsDisabled] = useState(
    props.reportStatus === 'Closed' || props.reportStatus === 'Rejected',
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const reportID = props.jsondata.reportID;

  const handleChange = event => {
    setComment(event.target.value);
  };

  const onStatusSelect = evt => {
    setShowModal(true);
    setPreviousStatus(currentStatus);
    setCurrentStatus(evt);
  };

  const cancelButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    setCurrentStatus(previousStatus);
  };

  const fetchActivityLog = (reportId = reportID, activity = '', comment = '') => {
    return new Promise((resolve, reject) => {
      (async () => {
        setLoading(true);
        try {
          await dispatch(activityLogCRAPayload({ reportID: reportId, activity, comment }));
          setError('');
          setComment('');
          setLoading(false);
          resolve();
        } catch (err) {
          setError('Unable to load data. Try again.');
          setLoading(false);
          reject();
        }
      })();
    });
  };

  const onValidation = status => {
    const activity = status !== undefined ? status : currentStatus;
    const comment = 'Status changed from ' + previousStatus + ' to ' + activity;
    fetchActivityLog(reportID, activity, comment).then(() =>
      window.alert(comment + ' successfully.'),
    );
  };

  const submitButtonTapped = () => {
    setShowModal(false);
    setIncorrectPIN(false);
    onValidation();
  };

  const onSubmitNewNote = () => {
    const activity = 'Note';
    if (comment.trim() === '' || comment === null || comment === undefined) {
      alert('Note field cannot be empty!');
      return;
    }
    fetchActivityLog(reportID, activity, comment);
  };

  useEffect(() => {
    if (props && props.jsondata && props.jsondata.PayloadStatus) {
      setPreviousStatus(props.jsondata.PayloadStatus);
      setCurrentStatus(props.jsondata.PayloadStatus);
    }
  }, []);

  useEffect(() => {
    if (activityLogData != null && activityLogData.length > 0) {
      if (
        props.jsondata.PayloadStatus === 'Closed' ||
        props.jsondata.PayloadStatus === 'Rejected'
      ) {
        return;
      }
      setIsDisabled(
        activityLogData[0].activity === 'Closed' || activityLogData[0].activity === 'Rejected',
      );
    }
  }, [activityLogData]);

  return (
    <div className="report_main_section">
      <span className="p4-black">Add new notes -</span>
      <div>
        <textarea
          className="commentbox"
          type="textarea"
          name="textValue"
          value={comment ?? ''}
          onChange={handleChange}
          disabled={isDisabled}
        />
        <Button
          className="dander-button"
          color="danger"
          outline
          size="lg"
          onClick={onSubmitNewNote}
          disabled={isDisabled}
          target="_blank"
          rel="noopener noreferrer">
          Save
        </Button>
      </div>

      <Row>
        <Col>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                fontSize: 14,
                color: '#808080',
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
                marginBottom: 10,
              }}>
              Current Status <span style={{ fontSize: 16, color: '#000000' }}>{currentStatus}</span>
            </div>
            <DropdownButton
              title="Set Status To"
              id="status-dropdown"
              style={{ fontFamily: 'Arial, sans-serif', fontSize: 16 }}
              disabled={isDisabled}
              onSelect={onStatusSelect}>
              <Dropdown.Item
                eventKey="Investigating"
                style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}>
                Investigating
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="Determination Complete"
                style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}>
                Determination Complete
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="Duplicate"
                style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}>
                Duplicate
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="Test Record"
                style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}>
                Test Record
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div>
            <span className="p4-black">Activity History</span>
            {/* table start*/}
            <div style={{ marginLeft: '10px' }}>
              <table
                id="default-view"
                className="condensed striped hover"
                style={{ overflow: 'auto' }}>
                <tbody>
                  <tr className="activitylog-tr">
                    <td className="p4-black-light">Time</td>
                    <td className="p4-black-light">User</td>
                    <td className="p4-black-light">Activity</td>
                    <td className="p4-black-light">Comments</td>
                  </tr>
                  {loading && <div className="p4-black-light">Loading...</div>}
                  {!loading && error.trim().length > 0 && (
                    <div className="p4-black-light">{error}</div>
                  )}
                  {!loading &&
                    error.trim().length === 0 &&
                    activityLogData &&
                    activityLogData.map((item, key) => (
                      <tr key={`ald-time-0${key}`}>
                        <td className="p4-black-light">
                          {isNaN()
                            ? new Date(item.activitytime)
                                .toLocaleString('en-US', {
                                  hour12: true,
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                })
                                .toUpperCase()
                            : new Date()
                                .toLocaleString('en-US', {
                                  hour12: true,
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                })
                                .toUpperCase()}
                        </td>
                        <td className="p4-black-light">{item.username}</td>
                        <td className="p4-black-light">{item.activity}</td>
                        <td className="p4-black-light">{item.comment}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <table id="responsive-view" className="condensed striped hover">
                <tbody>
                  {activityLogData &&
                    activityLogData.map((item, key) => (
                      <tr key={`ald-time-1${key}`}>
                        <td className="p4-black-light">{item.activitytime}</td>
                        <td className="p4-black-light">{item.username}</td>
                        <td className="p4-black-light">{item.activity}</td>
                        <td className="p4-black-light">{item.comment}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* table end*/}
          </div>
        </Col>
      </Row>
      {ReportConstants.displayInputJson ? (
        <div>
          <pre>{JSON.stringify(props.jsondata, null, 2)}</pre>{' '}
          <pre>{JSON.stringify(props.jsondataresponse, null, 2)}</pre>
        </div>
      ) : (
        ''
      )}
      <div>
        <PinEntryPopUp
          onHide={() => cancelButtonTapped()}
          show={showModal}
          setPIN={text => setEnteredPIN(text)}
          pinError={incorrectPIN}
          onSubmit={() => submitButtonTapped()}
        />
      </div>
    </div>
  );
};

// export default class ActivityLog extends Component {
//   static propTypes = {
//     jsondata: PropTypes.object.isRequired,
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       comment: null,
//       showModal: false,
//       enteredPIN: '',
//       incorrectPIN: false,
//       previousStatus: '',
//       currentStatus: '',
//       isDisabled: props.reportStatus === 'Closed' || props.reportStatus === 'Rejected',
//     };
//     this.handleChange = this.handleChange.bind(this);
//     this.onStatusSelect = this.onStatusSelect.bind(this);
//   }

//   componentDidMount() {
//     this.setState({
//       previousStatus: this.props.jsondata.PayloadStatus,
//       currentStatus: this.props.jsondata.PayloadStatus,
//     });
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (prevProps.jsondataresponse !== this.props.jsondataresponse) {
//       var { reportID, activitylog } = this.props.jsondata;
//       if (this.props.jsondataresponse) {
//         activitylog = this.props.jsondataresponse.Attributes.activitylog;
//       }
//       if (activitylog != null && activitylog.length > 0) {
//         if (
//           this.props.jsondata.PayloadStatus === 'Closed' ||
//           this.props.jsondata.PayloadStatus === 'Rejected'
//         ) {
//           return;
//         }
//         this.setState({
//           isDisabled:
//             activitylog[0].activity === 'Closed' || activitylog[0].activity === 'Rejected',
//         });
//       }
//     }
//   }

//   handleChange(event) {
//     this.setState({
//       comment: event.target.value,
//     });
//   }

//   onStatusSelect(evt) {
//     // if (evt !== 'Closed' && evt !== 'Rejected') {
//     //   this.onValidation(evt);
//     //   this.setState({ previousStatus: this.state.currentStatus, currentStatus: evt });
//     // } else {
//     this.setState({
//       showModal: true,
//       previousStatus: this.state.currentStatus,
//       currentStatus: evt,
//     });
//     // }
//   }

//   cancelButtonTapped = () => {
//     this.setState({
//       showModal: false,
//       incorrectPIN: false,
//       currentStatus: this.state.previousStatus,
//     });
//   };

//   onValidation = status => {
//     let reportID = this.props.jsondata.reportID;
//     let activity = status !== undefined ? status : this.state.currentStatus;
//     let comment = 'Status changed from ' + this.state.previousStatus + ' to ' + activity;

//     const { report, actions } = this.props;
//     const { activityLogCRAPayload } = actions;

//     activityLogCRAPayload({ reportID, activity, comment });

//     window.alert(comment + ' successfully.');
//   };

//   submitButtonTapped = () => {
//     this.setState({ showModal: false, incorrectPIN: false });
//     this.onValidation();
//   };

//   render() {
//     const { report, actions } = this.props;
//     const { activityLogCRAPayload } = actions;

//     var { reportID, activitylog } = this.props.jsondata;
//     if (this.props.jsondataresponse) {
//       activitylog = this.props.jsondataresponse.Attributes.activitylog;
//     }

//     return (
//       <div className="report_main_section" style={{ overflowX: 'auto' }}>
//         <span className="p4-black">Add new notes -</span>
//         <div>
//           <textarea
//             className="commentbox"
//             type="textarea"
//             name="textValue"
//             value={this.state.comment}
//             onChange={this.handleChange}
//             disabled={this.state.isDisabled}
//           />
//           <Button
//             className="dander-button"
//             color="danger"
//             outline
//             size="lg"
//             onClick={() => {
//               let activity = 'Note';
//               let comment = this.state.comment;
//               if (comment !== '' && comment !== null) {
//                 activityLogCRAPayload({ reportID, activity, comment });
//                 this.state.comment = '';
//               } else {
//                 alert('Note field cannot be empty!');
//               }
//             }}
//             disabled={this.state.isDisabled}
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Save
//           </Button>
//         </div>

//         <Row>
//           <Col>
//             <div style={{ marginLeft: 10 }}>
//               <div
//                 style={{
//                   fontSize: 14,
//                   color: '#808080',
//                   fontWeight: 'bold',
//                   fontFamily: 'Arial, sans-serif',
//                   marginBottom: 10,
//                 }}
//               >
//                 Current Status{' '}
//                 <span style={{ fontSize: 16, color: '#000000' }}>{this.state.currentStatus}</span>
//               </div>
//               <DropdownButton
//                 title="Set Status To"
//                 id="status-dropdown"
//                 style={{ fontFamily: 'Arial, sans-serif', fontSize: 16 }}
//                 disabled={this.state.isDisabled}
//                 onSelect={this.onStatusSelect}
//               >
//                 <Dropdown.Item
//                   eventKey="Investigating"
//                   style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}
//                 >
//                   Investigating
//                 </Dropdown.Item>
//                 <Dropdown.Item
//                   eventKey="Determination Complete"
//                   style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}
//                 >
//                   Determination Complete
//                 </Dropdown.Item>
//                 <Dropdown.Item
//                   eventKey="Duplicate"
//                   style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}
//                 >
//                   Duplicate
//                 </Dropdown.Item>
//                 <Dropdown.Item
//                   eventKey="Test Record"
//                   style={{ fontFamily: 'Arial, sans-serif', fontSize: 16, color: '#000000' }}
//                 >
//                   Test Record
//                 </Dropdown.Item>
//               </DropdownButton>
//             </div>
//             <div>
//               <span className="p4-black">Activity History</span>
//               {/* table start*/}
//               <div style={{ marginLeft: '10px' }}>
//                 <table id="default-view" className="condensed striped hover">
//                   <tr className="activitylog-tr">
//                     <td className="p4-black-light">Time</td>
//                     <td className="p4-black-light">User</td>
//                     <td className="p4-black-light">Activity</td>
//                     <td className="p4-black-light">Comments</td>
//                   </tr>
//                   {activitylog &&
//                     activitylog.map((item, key) => (
//                       <tr>
//                         <td className="p4-black-light">
//                           {isNaN(item.activitytime)
//                             ? item.activitytime
//                             : new Date(item.activitytime).toISOString()}
//                         </td>
//                         <td className="p4-black-light">{item.username}</td>
//                         <td className="p4-black-light">{item.activity}</td>
//                         <td className="p4-black-light">{item.comment}</td>
//                       </tr>
//                     ))}
//                 </table>
//                 <table id="responsive-view" className="condensed striped hover">
//                   {activitylog &&
//                     activitylog.map((item, key) => (
//                       <tr>
//                         <td className="p4-black-light">
//                           <tr>{item.activitytime}</tr>
//                           <tr>{item.username}</tr>
//                           <tr>{item.activity}</tr>
//                           <tr>{item.comment}</tr>
//                         </td>
//                       </tr>
//                     ))}
//                 </table>
//               </div>
//               {/* table end*/}
//             </div>
//           </Col>
//         </Row>
//         {ReportConstants.displayInputJson ? (
//           <div>
//             <pre>{JSON.stringify(this.props.jsondata, null, 2)}</pre>{' '}
//             <pre>{JSON.stringify(this.props.jsondataresponse, null, 2)}</pre>
//           </div>
//         ) : (
//           ''
//         )}
//         <div>
//           <PinEntryPopUp
//             onHide={() => this.cancelButtonTapped()}
//             show={this.state.showModal}
//             setPIN={text => this.setState({ enteredPIN: text })}
//             pinError={this.state.incorrectPIN}
//             onSubmit={() => this.submitButtonTapped()}
//           />
//         </div>
//       </div>
//     );
//   }
// }
